import axios, { AxiosInstance } from 'axios';
import { Vue } from 'vue-property-decorator';
import ApiError from '@/api/misc/ApiError';
import { GeneralApiError } from '@/api/misc/GeneralApiError';

/**
 * Base repository for auth repositories
 */
export default class AuthBaseRepository {

    protected axiosClient!: AxiosInstance;
    protected baseURL: string = process.env.VUE_APP_API_URL!;

    constructor() {
        this.createHttpClient();
        this.setResponseInterceptors();
        console.info('using host: ', this.baseURL);
    }

    private createHttpClient() {
        this.axiosClient = axios.create({
            baseURL: this.baseURL
        });
    }

    /**
     * Intercept response. Normalizes the response and handles certain error cases
     */
    private setResponseInterceptors() {
        this.axiosClient.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response) {
                    console.log(error, error.response);
                    // check for "special" errors that need special treatment
                    switch (error.response.status) {
                        // EXAMPLE:
                        // case 403:
                        //     // DO SOMETHING
                        //     break;
                        // Further cases ...
                        default: // 500 > any server error
                            throw new ApiError(error.response.status, error.message, error.response.data.data);
                        // need to throw an error here, otherwise the catch method will not trigger
                    }
                    return Promise.reject(new GeneralApiError());
                } else {
                    Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.NETWORK_ERROR');
                    return Promise.reject(new GeneralApiError());
                }
            }
        );
    }

}
