import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import './styles/main.scss';
import CustomNotifications from '@/plugins/CustomNotifications.plugin';
import LoadingComponent from '@/components/shared/LoadingComponent.vue';
import ConfirmDialogComponent from '@/components/shared/ConfirmDialogComponent.vue';
import { Settings } from 'luxon';
import TimeHandler from '@/plugins/TimeHandler.plugin';
// @ts-ignore
import DatetimePicker from 'vuetify-datetime-picker';
import PermissionHandlerPlugin from '@/plugins/PermissionHandler.plugin';

Vue.config.productionTip = false;

Vue.component('LoadingComponent', LoadingComponent);
Vue.component('ConfirmDialogComponent', ConfirmDialogComponent);

Vue.use(CustomNotifications);
Vue.use(TimeHandler);
Vue.use(PermissionHandlerPlugin);
Vue.use(DatetimePicker);
Settings.defaultLocale = i18n.locale;

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');
