import Parseable from '@/misc/Parseable';
import Voyage from '@/models/Voyage.model';
import {Booking} from '@/models/Booking.model';
import ShipOwner from '@/models/ShipOwner.model';
import StatusHistory, {StatusType} from '@/models/StatusHistory.model';
import {SlotType} from '@/enums/SlotType.enum';
import Slot from '@/models/Slot.model';

export default class ShipArrival extends Parseable {

    id?: string;
    carsImportVolume?: number | null;
    heavyImportVolume?: number | null;
    carsExportVolume?: number | null;
    heavyExportVolume?: number | null;
    voyage!: Voyage | string;
    shipOwner!: string | ShipOwner;
    booking!: Booking;
    hasRelevantChanges?: boolean;
    bookingHistory: string[] | Booking[] = [];
    historyChanges!: number;
    statusHistory: string[] | StatusHistory[] = [];

    constructor() {
        super();
        this.voyage = new Voyage();
        this.carsImportVolume = null;
        this.carsExportVolume = null;
        this.heavyExportVolume = null;
        this.heavyImportVolume = null;
    }

    public static parseFromObject(object: Partial<ShipArrival>): ShipArrival {
        const shipArrival = new ShipArrival();
        Object.assign(shipArrival, object);

        if (object && typeof object.voyage === 'object') {
            shipArrival.voyage = Voyage.parseFromObject(object.voyage!) as Voyage;
        }

        if (object && typeof object.shipOwner === 'object') {
            shipArrival.shipOwner = ShipOwner.parseFromObject(object.shipOwner) as ShipOwner;
        }

        if (object && Array.isArray(object.statusHistory)) {
            shipArrival.statusHistory = StatusHistory.parseFromArray(object.statusHistory) as StatusHistory[];
            shipArrival.historyChanges = shipArrival.statusHistory.filter(
                history => history.statusType === StatusType.UPDATED_VOYAGE ||
                    history.statusType === StatusType.UPDATED_IMPORT_EXPORT).length;
        } else {
            shipArrival.statusHistory = [];
            shipArrival.historyChanges = 0;
        }

        if (object && object.booking && typeof object.booking === 'object') {
            shipArrival.booking = Booking.parseFromObject(object.booking) as Booking;
        }

        if (object && Array.isArray(object.bookingHistory)
            && object.bookingHistory.length < 0 && typeof object.bookingHistory[0] !== 'string') {
            shipArrival.bookingHistory = Booking.parseFromArray(object.bookingHistory!) as Booking[];
        }

        if (!shipArrival.voyage) {
            shipArrival.voyage = new Voyage();
        }

        return shipArrival;
    }

    public parseToObject(): ShipArrival {
        return {...this};
    }

    public getRequiredCarSlots(): number {
        const carCount = (this.carsImportVolume! + this.carsExportVolume!);
        const necessarySlots = Math.ceil(carCount / process.env.VUE_APP_CAR_SLOT_VOLUME);
        if (!this.booking) {
            return necessarySlots;
        }
        const bookedSlots = (this!.booking as Booking).bookedSlots;
        const bookedSlotsCount = bookedSlots.filter(slot => slot.slotType === SlotType.CAR)
            .reduce((sum: number, slot: Slot) => sum + slot.amount!, 0);
        return necessarySlots - bookedSlotsCount;
    }

    public getRequiredHeavySlots(): number {
        const heavyCount = (this.heavyImportVolume! + this.heavyExportVolume!);
        const necessarySlots = Math.ceil(heavyCount / process.env.VUE_APP_HEAVY_SLOT_VOLUME);
        if (!this.booking) {
            return necessarySlots;
        }
        const bookedSlots = (this!.booking as Booking).bookedSlots;
        const bookedSlotsCount = bookedSlots.filter(slot => slot.slotType === SlotType.HEAVY)
            .reduce((sum: number, slot: Slot) => sum + slot.amount!, 0);
        return necessarySlots - bookedSlotsCount;
    }
}
