import { AuthState, RootState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import User from '@/models/User.model';
import AuthRepository from '@/api/repositories/Auth.repository';
import { RepositoryFactory } from '@/api/RepositoryFactory';

const authRepository: AuthRepository = RepositoryFactory.get('auth');

export enum authStoreMutations {
    SET_TOKEN = 'SET_TOKEN',
    SET_USER = 'SET_USER',
    CLEAR_STORE = 'CLEAR_STORE',
}

export enum authStoreActions {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
}

export enum authStoreGetter {
    TOKEN = 'TOKEN',
    CURRENT_USER = 'CURRENT_USER',
}

function initialAuthState(): AuthState {
    return {
        token: undefined,
        currentUser: undefined
    };
}

const store: AuthState = initialAuthState();

/**
 * ACTION SECTION
 */
const actions: ActionTree<AuthState, RootState> = {
    [authStoreActions.LOGIN]: async ({ commit }, payload: { username: string, password: string }): Promise<User> => {
        const response = await authRepository.login(payload);
        // Save token
        commit(authStoreMutations.SET_TOKEN, response.data.token);
        // Save user
        const user = User.parseFromObject({ ...response.data.user });
        commit(authStoreMutations.SET_USER, user);
        return user;
    },
    [authStoreActions.LOGOUT]: async ({ commit }): Promise<void> => {
        commit(authStoreMutations.CLEAR_STORE);
    },
};

/**
 * MUTATION SECTION
 */
const mutations: MutationTree<AuthState> = {
    [authStoreMutations.SET_TOKEN]: (state: AuthState, value: string | undefined) => {
        state.token = value;
    },
    [authStoreMutations.SET_USER]: (state: AuthState, value: User) => {
        state.currentUser = value;
    },
    [authStoreMutations.CLEAR_STORE]: (state: AuthState) => {
        const initialState = initialAuthState();
        Object.keys(initialState).forEach(key => {
            // @ts-ignore
            state[key] = initialState[key];
        });
    }
};

/**
 * GETTER SECTION
 */
const getters: GetterTree<AuthState, RootState> = {
    [authStoreGetter.TOKEN]: (state: AuthState) => state.token,
    [authStoreGetter.CURRENT_USER]: (state: AuthState) => User.parseFromObject(state.currentUser!)
};

const authStore: Module<AuthState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default authStore;
