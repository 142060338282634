import {AxiosResponse} from 'axios';
import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import {BookingState} from "@/enums/BookingState.enum";

export default class BookingRepository extends EntityBaseRepository {

    public setStatus(payload: { id: string, status: BookingState }): Promise<AxiosResponse> {
        return this.axiosClient.post(`/booking/${payload.id}/setStatus`, {status: payload.status});
    }
}
