











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LoadingComponent extends Vue {

    @Prop({ default: false })
    public isLoading!: boolean;

    @Prop({ default: '' })
    public loadingMessage!: string;
}
