import { RootState, UserState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import User from '@/models/User.model';
import UserRepository from '@/api/repositories/User.repository';

const userRepository: UserRepository = RepositoryFactory.get('user');

function initialUserState(): UserState {
    return {
        allUsers: [],
        selectedUser: undefined
    };
}

const store: UserState = initialUserState();

/**
 * ACTION SECTION
 */
export enum userStoreActions {
    GET = 'GET',
    GET_ALL = 'GET_ALL',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    ADD_SHIP_OWNER = 'ADD_SHIP_OWNER',
    REMOVE_SHIP_OWNER = 'REMOVE_SHIP_OWNER'
}

const actions: ActionTree<UserState, RootState> = {
    [userStoreActions.GET_ALL]: async ({ commit }): Promise<User[]> => {
        const result = await userRepository.getAll();
        const users = User.parseFromArray(result.data) as User[];
        commit(userStoreMutations.SET_ALL_USERS, users);
        return users;
    },
    // eslint-disable-next-line no-empty-pattern
    [userStoreActions.GET]: async ({}, payload: { id: string }): Promise<User> => {
        const result = await userRepository.getById(payload);
        const user = User.parseFromObject(result.data) as User;
        return user;
    },
    // eslint-disable-next-line no-empty-pattern
    [userStoreActions.CREATE]: async ({}, payload:
        { user: User }): Promise<User> => {
        const result = await userRepository.create(payload);
        const user = User.parseFromObject(result.data) as User;
        return user;
    },
    [userStoreActions.UPDATE]: async ({ commit }, payload:
        { user: User }): Promise<User> => {
        const result = await userRepository.update(payload);
        const user = User.parseFromObject(result.data) as User;
        commit(userStoreMutations.UPDATE_USER, user);
        return user;
    },
    [userStoreActions.ADD_SHIP_OWNER]: async ({ commit }, payload:
        { id: string, shipOwner: string }): Promise<User> => {
        const result = await userRepository.addShipOwner(payload);
        const user = User.parseFromObject(result.data) as User;
        commit(userStoreMutations.UPDATE_USER, user);
        return user;
    },
    [userStoreActions.REMOVE_SHIP_OWNER]: async ({ commit }, payload:
        { id: string, shipOwner: string }): Promise<User> => {
        const result = await userRepository.removeShipOwner(payload);
        const user = User.parseFromObject(result.data) as User;
        commit(userStoreMutations.UPDATE_USER, user);
        return user;
    },
};

/**
 * MUTATION SECTION
 */
export enum userStoreMutations {
    SET_ALL_USERS = 'SET_ALL_USER',
    SET_SELECTED_USER = 'SET_SELECTED_USER',
    UPDATE_USER = 'UPDATE_USER',
    CLEAR_STORE = 'CLEAR_STORE',
}

const mutations: MutationTree<UserState> = {
    [userStoreMutations.SET_ALL_USERS]: (state: UserState, value: User[]) => {
        state.allUsers = value;
    },
    [userStoreMutations.SET_SELECTED_USER]: (state: UserState, value: User) => {
        state.selectedUser = value;
    },
    [userStoreMutations.UPDATE_USER]: (state: UserState, value: User) => {
        const index = state.allUsers
            .findIndex(item =>
                item.id === value.id
            );
        state.allUsers[index] = value;
        state.allUsers = [...state.allUsers];
    },
    [userStoreMutations.CLEAR_STORE]: (state: UserState) => {
        const initialState = initialUserState();
        Object.keys(initialState).forEach(key => {
            // @ts-ignore
            state[key] = initialState[key];
        });
    },
};

/**
 * GETTER SECTION
 */
export enum userStoreGetter {
    LIST_ALL = 'LIST_ALL',
    SELECTED_USER = 'SELECTED_USER'

}

const getters: GetterTree<UserState, RootState> = {
    [userStoreGetter.LIST_ALL]: (state: UserState) => {
        return User.parseFromArray(state.allUsers);
    },
    [userStoreGetter.SELECTED_USER]: (state: UserState) => {
        return state.selectedUser ? User.parseFromObject(state.selectedUser) : null;
    },
};

const userStore: Module<UserState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default userStore;
