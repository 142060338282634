import {RootState, VesselState} from '@/interfaces/storeStateInterfaces';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import Vessel from "@/models/Vessel.model";
import VesselRepository from "@/api/repositories/Vessel.repository";

const vesselRepository: VesselRepository = RepositoryFactory.get('vessel');

function initialVesselState(): VesselState {
    return {
        allVessels: []
    };
}

const store: VesselState = initialVesselState();

/**
 * ACTION SECTION
 */
export enum vesselStoreActions {
    GET = 'GET',
    GET_ALL = 'GET_ALL',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

const actions: ActionTree<VesselState, RootState> = {
    [vesselStoreActions.GET_ALL]: async ({commit}, payload?: { filterText: string }): Promise<Vessel[]> => {
        const result = await vesselRepository.getAll(payload);
        const vessels = Vessel.parseFromArray(result.data) as Vessel[];
        commit(vesselStoreMutations.SET_ALL_VESSELS, vessels);
        return vessels;
    },
    [vesselStoreActions.GET]: async ({commit}, payload: { id: string }): Promise<Vessel> => {
        const result = await vesselRepository.getById(payload);
        const vessel = Vessel.parseFromObject(result.data) as Vessel;
        return vessel;
    },
    [vesselStoreActions.UPDATE]: async ({commit}, payload:
        { id: string, status: boolean }): Promise<Vessel> => {
        const result = await vesselRepository.update(payload);
        const vessel = Vessel.parseFromObject(result.data) as Vessel;
        commit(vesselStoreMutations.UPDATE_VESSEL, vessel);
        return vessel;
    },
    [vesselStoreActions.DELETE]: async ({commit}, payload:
        { id: string }): Promise<Vessel> => {
        const result = await vesselRepository.delete(payload);
        const vessel = Vessel.parseFromObject(result.data) as Vessel;
        commit(vesselStoreMutations.REMOVE_VESSEL, vessel);
        return vessel;
    }
};

/**
 * MUTATION SECTION
 */
export enum vesselStoreMutations {
    SET_ALL_VESSELS = 'SET_ALL_VESSELS',
    UPDATE_VESSEL = 'UPDATE_VESSEL',
    CLEAR_STORE = 'CLEAR_STORE',
    REMOVE_VESSEL = 'REMOVE_VESSEL'
}

const mutations: MutationTree<VesselState> = {
    [vesselStoreMutations.SET_ALL_VESSELS]: (state: VesselState, value: Vessel[]) => {
        state.allVessels = value;
    },
    [vesselStoreMutations.UPDATE_VESSEL]: (state: VesselState, value: Vessel) => {
        const index = state.allVessels
            .findIndex(item =>
                item.id === value.id
            );
        state.allVessels[index] = value;
        state.allVessels = [...state.allVessels];
    },
    [vesselStoreMutations.REMOVE_VESSEL]: (state: VesselState, value: Vessel) => {
        state.allVessels = state.allVessels.filter(vessel => vessel.id !== value.id);
    },
    [vesselStoreMutations.CLEAR_STORE]: (state: VesselState) => {
        const initialState = initialVesselState();
        Object.keys(initialState).forEach(key => {
            // @ts-ignore
            state[key] = initialState[key];
        });
    },
};

/**
 * GETTER SECTION
 */
export enum vesselStoreGetter {
    LIST_ALL = 'LIST_ALL',
}

const getters: GetterTree<VesselState, RootState> = {
    [vesselStoreGetter.LIST_ALL]: (state: VesselState) => {
        return Vessel.parseFromArray(state.allVessels);
    },
};

const vesselStore: Module<VesselState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default vesselStore;
