import Vue from 'vue';
import VueRouter from 'vue-router';
import {ROUTE_CALENDAR, ROUTE_HOME, ROUTE_LOGIN, ROUTE_SETTINGS, ROUTE_SHIP_ARRIVAL,} from '@/router/routes';
import {navigationMenuItems} from '@/misc/navigationMenuItems';
import store from '@/store';
import {authStoreGetter} from '@/store/auth.store';

Vue.use(VueRouter);

// Handle NavigationDuplicated stack error:
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push;
// @ts-ignore
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    // @ts-ignore
    return originalPush.call(this, location).catch((err) => err);
};

const routes = [
    {
        path: '/login',
        name: ROUTE_LOGIN,
        component: () => import(/* webpackChunkName: "LoginView" */ '../views/Login.view.vue')
    },
    {
        path: '/',
        name: ROUTE_HOME,
        beforeEnter: async (to: any, from: any, next: any) => {
            // Check if token is set. If not, redirect to login
            if (store.getters[`auth/${authStoreGetter.TOKEN}`]) {
                await next();
            } else {
                await next({name: ROUTE_LOGIN});
            }
        },
        redirect: {name: ROUTE_CALENDAR},
        component: () => import(/* webpackChunkName: "HomeView" */ '../views/Home.view.vue'),
        children: [{
            path: '/calendar',
            name: ROUTE_CALENDAR,
            component: () => import(/* webpackChunkName: "CalendarView" */ '../views/Calendar.view.vue'),
            beforeEnter: async (to: any, from: any, next: any) => {
                const navElement = navigationMenuItems.find(item => item.route === ROUTE_CALENDAR);
                if (navElement && Vue.hasRole(navElement.neededUserRole)) {
                    await next();
                } else {
                    await next({name: ROUTE_SHIP_ARRIVAL});
                }
            }
        },
            {
                path: '/shipArrival',
                name: ROUTE_SHIP_ARRIVAL,
                component: () => import(/* webpackChunkName: "ShipArrivalView" */ '../views/ShipArrival.view.vue'),

            }, {
                path: '/settings',
                name: ROUTE_SETTINGS,
                component: () => import(/* webpackChunkName: "SettingsView" */ '../views/Settings.view.vue'),
                beforeEnter: async (to: any, from: any, next: any) => {
                    const navElement = navigationMenuItems.find(item => item.route === ROUTE_SETTINGS);
                    if (navElement && Vue.hasRole(navElement.neededUserRole)) {
                        await next();
                    } else {
                        await next({name: ROUTE_SHIP_ARRIVAL});
                    }
                }
            },
        ]
    },
    /**
     * Matches everything which were not matches before...
     */
    {
        path: '*',
        redirect: {name: 'home'}
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
