import {AxiosResponse} from 'axios';
import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import User from '@/models/User.model';

export default class UserRepository extends EntityBaseRepository {

    public getAll(): Promise<AxiosResponse> {
        return this.axiosClient.get('/user');
    }

    public getById(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.get(`/user/${payload.id}`);
    }

    public addShipOwner(payload: { id: string, shipOwner: string }): Promise<AxiosResponse> {
        return this.axiosClient.post(`/user/${payload.id}/addShipOwner`, {shipOwner: payload.shipOwner});
    }

    public removeShipOwner(payload: { id: string, shipOwner: string }): Promise<AxiosResponse> {
        return this.axiosClient.post(`/user/${payload.id}/removeShipOwner`, {shipOwner: payload.shipOwner});
    }

    public update(payload: { user: User }): Promise<AxiosResponse> {
        const params: any = payload.user;
        return this.axiosClient.put(`/user/${payload.user.id}`, params);
    }

    public create(payload: { user: User }): Promise<AxiosResponse> {
        const params: any = payload.user;
        return this.axiosClient.post('/user', params);
    }
}
