import Notifications from 'vue-notification';
import { Vue as _Vue } from 'vue-property-decorator';
import i18n from '@/i18n';

/**
 * A small plugin to abstract and ease the invocation of vue-notification notifications.
 */

// Register base vue-notification plugin
_Vue.use(Notifications);

/**
 * Default options
 */
let defaultOptions = {
    duration: 3000
};

const CustomNotifications = {
    install(Vue: any, options: any = {}) {

        // handle options
        defaultOptions = Object.assign(defaultOptions, options);

        /**
         * Abstraction wrapper for success notification
         * @param title
         * @param text
         * @param duration
         */
        const notifySuccess = (title: string, text: string, duration?: number) => {

            // handle custom duration
            const callOptions = { ...defaultOptions };
            callOptions.duration = duration || callOptions.duration;

            Vue.notify({
                group: 'notification',
                title,
                text,
                type: 'success',
                ...callOptions
            });
        };
        Vue.prototype.$notifySuccess = notifySuccess;
        Vue.notifySuccess = notifySuccess;

        /**
         * Abstraction wrapper for error notification
         * @param title
         * @param text
         * @param duration
         */
        const notifyError = (title: string, text: string, duration?: number) => {

            // handle custom duration
            const callOptions = { ...defaultOptions };
            callOptions.duration = duration || callOptions.duration;

            Vue.notify({
                group: 'notification',
                title,
                text,
                type: 'error',
                ...callOptions
            });
        };
        Vue.prototype.$notifyError = notifyError;
        Vue.notifyError = notifyError;

        /**
         * Abstraction wrapper for info notification
         * @param title
         * @param text
         * @param duration
         */
        const notifyInfo = (title: string, text: string, duration?: number) => {

            // handle custom duration
            const callOptions = { ...defaultOptions };
            callOptions.duration = duration ?? callOptions.duration;

            Vue.notify({
                group: 'notification',
                title,
                text,
                type: 'info',
                ...callOptions
            });
        };
        Vue.prototype.$notifyInfo = notifyInfo;
        Vue.notifyInfo = notifyInfo;

        /**
         * Abstraction wrapper for warning notification
         * @param title
         * @param text
         * @param duration
         */
        const notifyWarning = (title: string, text: string, duration?: number) => {

            // handle custom duration
            const callOptions = { ...defaultOptions };
            callOptions.duration = duration || callOptions.duration;

            Vue.notify({
                group: 'notification',
                title,
                text,
                type: 'warning',
                ...callOptions
            });
        };
        Vue.prototype.$notifyWarning = notifyWarning;
        Vue.notifyWarning = notifyWarning;

        /**
         * Simplified version of an success notification.
         * @param key
         * @param duration
         * @param data
         */
        const notifySuccessSimplified = (key: string, data?: any, duration?: number) =>
            notifySuccess(
                i18n.t(`${key}.TITLE`, data).toString(),
                i18n.t(`${key}.TEXT`, data).toString(),
                duration
            );
        Vue.prototype.$notifySuccessSimplified = notifySuccessSimplified;
        Vue.notifySuccessSimplified = notifySuccessSimplified;

        /**
         * Simplified version of an error notification.
         * @param key
         * @param duration
         * @param data
         */
        const notifyErrorSimplified = (key: string, data?: any, duration?: number) =>
            notifyError(
                i18n.t(`${key}.TITLE`, data).toString(),
                i18n.t(`${key}.TEXT`, data).toString(),
                duration
            );
        Vue.prototype.$notifyErrorSimplified = notifyErrorSimplified;
        Vue.notifyErrorSimplified = notifyErrorSimplified;

        /**
         * Simplified version of an error notification.
         * @param key
         * @param duration
         * @param data
         */
        const notifyInfoSimplified = (key: string, data?: any, duration?: number) =>
            notifyInfo(
                i18n.t(`${key}.TITLE`, data).toString(),
                i18n.t(`${key}.TEXT`, data).toString(),
                duration
            );

        Vue.prototype.$notifyInfoSimplified = notifyInfoSimplified;
        Vue.notifyInfoSimplified = notifyInfoSimplified;

        /**
         * Simplified version of an warning notification.
         * @param key
         * @param duration
         * @param data
         */
        const notifyWarningSimplified = (key: string, data?: any, duration?: number) =>
            notifyWarning(
                i18n.t(`${key}.TITLE`, data).toString(),
                i18n.t(`${key}.TEXT`, data).toString(),
                duration
            );
        Vue.prototype.$notifyWarningSimplified = notifyWarningSimplified;
        Vue.notifyWarningSimplified = notifyWarningSimplified;
    }
};
export default CustomNotifications;
