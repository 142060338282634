import { DateTime } from 'luxon';
import CalendarItemIsSelectedDefinition from '@/interfaces/CalendarItemBorderDefinition.interface';
import CalendarOuterValues from '@/interfaces/CalendarOuterValues.interface';

/**
 * Support convert method (map to array). The instance of condition is necessary, because the persistent plugin
 * creates plain objects at first instead of map objects
 * @param map
 */
export const mapToArray = (map: Map<any, any>): any[] => map instanceof Map ? Array.from(map.values()) : [];
/**
 * Support convert method (map to map). The instance of condition is necessary, because the persistent plugin
 * creates plain objects at first instead of map objects
 * @param map
 */
export const mapToMap = (map: Map<any, any>): Map<any, any> => map instanceof Map ? map : new Map();
/**
 * Support convert method (set to set). The instance of condition is necessary, because the persistent plugin
 * creates plain objects at first instead of set objects
 * @param map
 */
export const setToSet = (set: Set<any>): Set<any> => set instanceof Set ? set : new Set();
/**
 * Reconverts a string coordinate to a number array coordinate
 * @param coordinate
 */
export const calendarCoordsConverting = (coordinate: string): number[] => coordinate.split('-').map(item => Number(item));
/**
 * Creates a string coordinate from an index and a iso date
 * @param coordinate
 */
export const createCoordinates = (xIndex: number, yIndex: number): string => {
    return `${xIndex}-${yIndex}`;
}

/**
 * Support function which determines the outer values
 * @param selection
 */
export const determineOuterValues = (selection: Set<string>): CalendarOuterValues => {
    // Determine outer values
    const outerValues = {
        x: { min: 0, max: 0 },
        y: { min: 0, max: 0 }
    };
    if (selection.size > 0) {
        outerValues.x.min = Infinity;
        outerValues.y.min = Infinity;
    }
    selection.forEach(item => {
        const coords = calendarCoordsConverting(item);
        outerValues.x.min = Math.min(outerValues.x.min, coords[0]);
        outerValues.x.max = Math.max(outerValues.x.max, coords[0]);
        outerValues.y.min = Math.min(outerValues.y.min, coords[1]);
        outerValues.y.max = Math.max(outerValues.y.max, coords[1]);
    });
    return outerValues;
};

/**
 * Checks if the given coordinate is inside the Outervalues
 * @param outerValues
 * @param coordinate
 */
export const isCoordinateInsideOuterValues = (outerValues: CalendarOuterValues, coordinate: string): boolean => {
    const [x, y] = calendarCoordsConverting(coordinate);
    return (outerValues.x.min <= x && x <= outerValues.x.max) && (outerValues.y.min <= y && y <= outerValues.y.max);
};
/**
 * Creates item selected definition for handling calender interaction
 * @param selection
 * @param coordinate
 */
export const createCalendarItemIsSelectedDefinition = (selection: Set<string>, coordinate: string): CalendarItemIsSelectedDefinition => {

    const [x, y] = calendarCoordsConverting(coordinate);
    const outerValues = determineOuterValues(selection);
    return {
        isSelected: selection.size > 0 && isCoordinateInsideOuterValues(outerValues, coordinate),
        border: {
            top: selection.size > 0 && isCoordinateInsideOuterValues(outerValues, coordinate) && !isCoordinateInsideOuterValues(outerValues, `${x}-${y - 1}`),
            right: selection.size > 0 && isCoordinateInsideOuterValues(outerValues, coordinate) && !isCoordinateInsideOuterValues(outerValues, `${x + 1}-${y}`),
            bottom: selection.size > 0 && isCoordinateInsideOuterValues(outerValues, coordinate) && !isCoordinateInsideOuterValues(outerValues, `${x}-${y + 1}`),
            left: selection.size > 0 && isCoordinateInsideOuterValues(outerValues, coordinate) && !isCoordinateInsideOuterValues(outerValues, `${x - 1}-${y}`)
        }
    };
};
/**
 * Creates an coordinates matrix based on two rectangle points
 * @param coordinates
 */
export const createCoordinateMatrix = (coordinates: Set<string>): string[] => {
    const outerValues = determineOuterValues(coordinates);
    const matrix = [];
    // Create matrix based on outer values
    for (let i = outerValues.x.min; i <= outerValues.x.max; i++) {
        for (let j = outerValues.y.min; j <= outerValues.y.max; j++) {
            matrix.push(`${i}-${j}`);
        }
    }
    return matrix;
};
/**
 * Determines the dimension (with and height with direction (+ and -))
 * @param selection
 */
export const determineOuterValuesDimension = (selection: Set<string>): number[] => {
    const outerValues = determineOuterValues(selection);
    return [outerValues.x.max - outerValues.x.min, outerValues.y.max - outerValues.y.min];
};
/**
 * Extends the given coords with the selection dimension of the given selection and creates a new selection.
 * @param coords
 * @param selection
 */
export const extendCoordinates = (coords: string, selection: Set<string>): Set<string> => {
    const dimension = determineOuterValuesDimension(selection);
    const [x, y] = calendarCoordsConverting(coords);
    const extendedCoords = new Set<string>();
    for (let i = 0; i <= dimension[0]; i++) {
        for (let j = 0; j <= dimension[1]; j++) {
            extendedCoords.add(`${x + i}-${y + j}`);
        }
    }
    return extendedCoords;
};

/**
 * Changes the week number and respects year changes.
 * @param dateTime
 * @param change
 */
export const changeWeekNumber = (dateTime: DateTime, change: 1 | -1): DateTime => {
    let dateObjectData;
    switch (change) {
        case 1:
            // Check if system has to jump into next year
            if (dateTime.weekNumber + 1 > dateTime.weeksInWeekYear) {
                dateObjectData = {
                    weekYear: dateTime.year + 1,
                    weekNumber: dateTime.weekNumber + 1 - dateTime.weeksInWeekYear
                };
            } else {
                // If not, just increase the weeknumber
                dateObjectData = {
                    weekYear: dateTime.year,
                    weekNumber: dateTime.weekNumber + 1
                };
            }
            break;
        case -1:
            // Check if system has to jump into previous year
            if (dateTime.weekNumber - 1 === 0) {
                dateObjectData = {
                    weekYear: dateTime.year - 1,
                    weekNumber: dateTime.minus({ year: 1 }).weeksInWeekYear
                };
            } else {
                // If not, just increase the weeknumber
                dateObjectData = {
                    weekYear: dateTime.year,
                    weekNumber: dateTime.weekNumber - 1
                };
            }
            break;
    }
    return DateTime.fromObject({ ...dateObjectData, zone: 'utc' });
}
