import {AxiosResponse} from 'axios';
import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import {ShiftType} from '@/enums/ShiftType.enum';
import SlotBookingRequest from '@/interfaces/SlotBookingRequest.interface';
import Slot from '@/models/Slot.model';
import {SlotType} from '@/enums/SlotType.enum';
import {SlotConfigEntryInterface} from '@/interfaces/SlotConfig.interface';

export default class SlotRepository extends EntityBaseRepository {

    public getSlotGroup(payload: { shift: ShiftType, date: string }): Promise<AxiosResponse> {
        return this.axiosClient.get(`/booking/getDataForDayAndShift?shift=${payload.shift}&date=${payload.date}`);
    }

    public createSlot(payload: SlotBookingRequest): Promise<AxiosResponse> {
        return this.axiosClient.post('slot', payload);
    }

    public deleteSlot(payload: Slot): Promise<AxiosResponse> {
        return this.axiosClient.delete(`slot/${payload.id}`);
    }

    public checkMoveSlot(payload: { slot: Slot, date: string, shift: ShiftType }): Promise<AxiosResponse> {
        return this.axiosClient.post(`slot/${payload.slot.id}/checkMoveSlot`,
            { date: payload.date, shift: payload.shift });
    }

    public moveSlot(payload: { slot: Slot, date: string, shift: ShiftType }): Promise<AxiosResponse> {
        return this.axiosClient.post(`slot/${payload.slot.id}/move`,
            { date: payload.date, shift: payload.shift });
    }

    public increaseExtraSlot(payload: { shift: ShiftType, date: string, slotType: SlotType }): Promise<AxiosResponse> {
        return this.axiosClient.post('/extraSlot/increaseExtraSlot', payload);
    }

    public decreaseExtraSlot(payload: { shift: ShiftType, date: string, slotType: SlotType }): Promise<AxiosResponse> {
        return this.axiosClient.post('/extraSlot/decreaseExtraSlot', payload);
    }

    public getShiftConfig(): Promise<AxiosResponse> {
        return this.axiosClient.get('slotConfig/getShiftConfig');
    }

    public getSlotConfig(payload: { calendarWeek: number, year: number }): Promise<AxiosResponse> {
        const searchParams = new URLSearchParams();
        searchParams.append('year', payload.year.toString());
        searchParams.append('calendarWeek', payload.calendarWeek.toString());
        const queryParams = `?${searchParams.toString()}`;
        return this.axiosClient.get( `slotConfig${queryParams}`);
    }

    public updateSlotConfig(slotEntry: SlotConfigEntryInterface): Promise<AxiosResponse> {
        return this.axiosClient.put(`slotConfig/${slotEntry.id}`,
            { [SlotType.CAR]: slotEntry.availableSlots.CAR, [SlotType.HEAVY]: slotEntry.availableSlots.HEAVY });
    }
}
