import axios, {AxiosInstance} from 'axios';
import {Vue} from 'vue-property-decorator';
import ApiError from '@/api/misc/ApiError';
import store from '@/store';
import {authStoreGetter, authStoreMutations} from '@/store/auth.store';
import router from '@/router';
import {GeneralApiError} from '@/api/misc/GeneralApiError';

/**
 * Base repository class
 */
export default class EntityBaseRepository {

    protected axiosClient!: AxiosInstance;
    protected baseURL = `${process.env.VUE_APP_API_URL!}api/`;
    protected hasShownAuthError: boolean = false;

    constructor() {
        this.createHttpClient();
        this.setRequestInterceptors();
        this.setResponseInterceptors();
    }

    private createHttpClient() {
        this.axiosClient = axios.create({
            baseURL: this.baseURL
        });
    }

    /**
     * Sets the request interceptors
     * @private
     */
    private setRequestInterceptors() {
        this.axiosClient.interceptors.request.use((config) => {
            // OPTIMIZE this is not optimal, the token could be expired ...
            if (store.getters[`auth/${authStoreGetter.TOKEN}`]) {
                config.headers.authorization = `Bearer ${store.getters[`auth/${authStoreGetter.TOKEN}`]}`;
            }
            return config;
        }, (error) => {
            throw Promise.reject(error);
        });
    }

    /**
     * Intercept response. Normalizes the response and handles certain error cases
     */
    private setResponseInterceptors() {
        this.axiosClient.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response) {
                    // console.log(error, error.response);
                    // check for "special" errors that need special treatment
                    switch (error.response.status) {
                        // handle invalid or expired token
                        case 401:
                        case 403: {
                            // Show this error only ones
                            if (!this.hasShownAuthError) {
                                this.hasShownAuthError = true;
                                store.commit(`auth/${authStoreMutations.CLEAR_STORE}`);
                                router.push({name: 'login'});
                                Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
                                setTimeout(() => {
                                    this.hasShownAuthError = false;
                                }, 3000);
                            }
                            break;
                        }
                        // Further cases
                        default: // 500 > any server error
                            // need to throw an error here, otherwise the catch method will not trigger
                            throw new ApiError(error.response.status, error.message, error.response.data.data);
                    }
                    return Promise.reject(new GeneralApiError());
                } else {
                    Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.NETWORK_ERROR');
                    return Promise.reject(new GeneralApiError());
                }
            }
        );
    }
}
