import {HarborState, RootState} from '@/interfaces/storeStateInterfaces';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import HarborRepository from '@/api/repositories/Harbor.repository';
import Harbor from '@/models/Harbor';

const harborRepository: HarborRepository = RepositoryFactory.get('harbor');

function initialHarborState(): HarborState {
  return {
    harbors: []
  };
}

const store: HarborState = initialHarborState();

/**
 * ACTION SECTION
 */
export enum harborStoreActions {
  GET_ALL = 'GET_ALL'
}

const actions: ActionTree<HarborState, RootState> = {
  [harborStoreActions.GET_ALL]: async ({commit}): Promise<Harbor[]> => {
    const result = await harborRepository.getHarbours();
    const harbors = Harbor.parseFromArray(result.data) as Harbor[];
    commit(harborStoreMutations.SET_HARBORS, harbors);
    return harbors;
  },
};

/**
 * MUTATION SECTION
 */
export enum harborStoreMutations {
  SET_HARBORS = 'SET_HARBORS',
  CLEAR_STORE = 'CLEAR_STORE',
}

const mutations: MutationTree<HarborState> = {
  [harborStoreMutations.SET_HARBORS]: (state: HarborState, value: Harbor[]) => {
    state.harbors = value;
  },
  [harborStoreMutations.CLEAR_STORE]: (state: HarborState) => {
    const initialState = initialHarborState();
    Object.keys(initialState).forEach(key => {
      // @ts-ignore
      state[key] = initialState[key];
    });
  },
};

/**
 * GETTER SECTION
 */
export enum harborStoreGetter {
  LIST_ALL = 'LIST_ALL',
}

const getters: GetterTree<HarborState, RootState> = {
  [harborStoreGetter.LIST_ALL]: (state: HarborState) => {
    return Harbor.parseFromArray(state.harbors);
  },
};

const harborStore: Module<HarborState, RootState> = {
  state: store,
  actions,
  mutations,
  getters
};

export default harborStore;
