import Parseable from '@/misc/Parseable';
import {BookingState} from '@/enums/BookingState.enum';
import Slot from '@/models/Slot.model';
import User from '@/models/User.model';
import Vessel from '@/models/Vessel.model';

export enum StatusType {
    CREATED = 'CREATED',
    DELETED = 'DELETED',
    CREATED_SLOT = 'CREATED_SLOT',
    STATUS_CHANGED = 'STATUS_CHANGED',
    DELETED_SLOT = 'DELETED_SLOT',
    UPDATED_VOYAGE = 'UPDATED_VOYAGE',
    UPDATED_IMPORT_EXPORT = 'UPDATED_IMPORT_EXPORT',
}

export interface VoyageUpdate {
    oldVoyageNoImport: string,
    oldVoyageNoExport: string,
    updatedVoyageNoImport: string,
    updatedVoyageNoExport: string,
    oldEta: Date | string,
    oldEts: Date | string,
    updatedEta: Date | string,
    updatedEts: Date | string,
    oldVessel: Vessel,
    updatedVessel: Vessel
}

export interface ArrivalUpdate {
    oldCarsImportVolume: number,
    oldHeavyImportVolume: number,
    oldCarsExportVolume: number,
    oldHeavyExportVolume: number,
    updatedCarsImportVolume: number,
    updatedHeavyImportVolume: number,
    updatedCarsExportVolume: number,
    updatedHeavyExportVolume: number,
}

export default class StatusHistory extends Parseable {
    id!: string;
    statusType!: StatusType;
    currentStatus!: BookingState;
    slot!: Slot;
    createdAt!: string;
    user!: User;
    voyageUpdate?: VoyageUpdate;
    arrivalUpdate?: ArrivalUpdate;

    public static parseFromObject(object: Partial<StatusHistory>): StatusHistory {
        const history = new StatusHistory();
        Object.assign(history, object);

        if (object.user) {
            history.user = User.parseFromObject(object.user);
        }

        if (object.slot) {
            history.slot = Slot.parseFromObject(object.slot);
        }

        if (object.voyageUpdate && object.voyageUpdate.updatedVessel) {
            history.voyageUpdate!.updatedVessel = object.voyageUpdate.updatedVessel;
        }

        if (object.voyageUpdate && object.voyageUpdate.oldVessel) {
            history.voyageUpdate!.oldVessel = object.voyageUpdate.oldVessel;
        }

        return history;
    }

    public parseToObject(): StatusHistory {
        return { ...this };
    }
}
