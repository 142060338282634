import {ApplicationState, RootState} from '@/interfaces/storeStateInterfaces';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';

function initialApplicationState(): ApplicationState {
    return {
        locale: 'de'
    };
}

const store: ApplicationState = initialApplicationState();

/**
 * ACTION SECTION
 */
export enum applicationStoreActions {
}

const actions: ActionTree<ApplicationState, RootState> = {};

/**
 * MUTATION SECTION
 */
export enum applicationStoreMutations {
    SET_LOCALE = 'SET_LOCALE',
    SET_IS_MAIN_DATA_LOADING = 'SET_IS_MAIN_DATA_LOADING',
    CLEAR_STORE = 'CLEAR_STORE',
}

const mutations: MutationTree<ApplicationState> = {
    [applicationStoreMutations.SET_LOCALE]: (state: ApplicationState, locale: string) => {
        state.locale = locale;
    },
    [applicationStoreMutations.CLEAR_STORE]: (state: ApplicationState) => {
        const initialState = initialApplicationState();
        Object.keys(initialState).forEach(key => {
            // @ts-ignore
            state[key] = initialState[key];
        });
    }
};

/**
 * GETTER SECTION
 */
export enum applicationStoreGetter {
    LOCALE = 'LOCALE'
}

const getters: GetterTree<ApplicationState, RootState> = {
    [applicationStoreGetter.LOCALE]: (state: ApplicationState) => state.locale,
};

const applicationStore: Module<ApplicationState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default applicationStore;
