import {AxiosResponse} from 'axios';
import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import {SlotSettings} from "@/interfaces/SlotSettings.interface";

export default class SettingsRepository extends EntityBaseRepository {

    public getSettings(): Promise<AxiosResponse> {
        return this.axiosClient.get('/slotSettings');
    }

    public updateSettings(settings: SlotSettings): Promise<AxiosResponse> {
        return this.axiosClient.put(`slotSettings/${settings.id}`,
            {lockedAmount: settings.lockedAmount,
                userDeletionTime: settings.userDeletionTime,
                arrivalDeletionTime: settings.arrivalDeletionTime});
    }
}
