import ApiError from '@/api/misc/ApiError';

/**
 * Represents a general error. A general error is an error which is basically
 * handled in one of the base repositories.
 */
export class GeneralApiError extends ApiError {
    constructor(data?: any) {
        super(0, 'GENERAL_ERROR', data);
    }
}
