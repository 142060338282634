import Parseable from '@/misc/Parseable';
import Slot from '@/models/Slot.model';
import { ShiftType } from '@/enums/ShiftType.enum';
import { ShiftLength } from '@/enums/ShiftLength.enum';

export default class SlotGroup extends Parseable {

    date!: string;
    shift!: ShiftType;
    shiftLength!: ShiftLength;

    bookedCarSlotsCount!: number;
    bookedHeavySlotsCount!: number;

    public availableSlots: {
        CAR: number,
        HEAVY: number
    } = {
        CAR: 0,
        HEAVY: 0
    };
    public bookedSlots: {
        cars: Slot[],
        heavy: Slot[],
    } = {
        cars: [],
        heavy: []
    };

    get maxAvailableSlots(): number {
        return Math.max(
            this.availableSlots.CAR + this.bookedCarSlotsCount,
            this.availableSlots.HEAVY + this.bookedHeavySlotsCount
        );
    }

    public static parseFromObject(object: any): SlotGroup {
        const slotGroup = new SlotGroup();
        object.bookedSlots = {
            cars: Slot.parseFromArray(object.bookedSlots!.cars) as Slot[],
            heavy: Slot.parseFromArray(object.bookedSlots!.heavy) as Slot[],
        };
        object = { ...object, ...object.data };
        delete object.data;

        Object.assign(slotGroup, object);
        return slotGroup;
    }

    parseToObject(): Partial<SlotGroup> {
        return { ...this };
    }
}
