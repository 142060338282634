import AuthRepository from '@/api/repositories/Auth.repository';
import SlotRepository from '@/api/repositories/Slot.repository';
import ShipArrivalRepository from '@/api/repositories/ShipArrival.repository';
import BookingRepository from "@/api/repositories/Booking.repository";
import VesselRepository from "@/api/repositories/Vessel.repository";
import UploadRepository from "@/api/repositories/Upload.repository";
import VoyageRepository from '@/api/repositories/Voyage.repository';
import UserRepository from '@/api/repositories/User.repository';
import ShipOwnerRepository from '@/api/repositories/ShipOwner.repository';
import SettingsRepository from "@/api/repositories/Settings.repository";
import HarborRepository from "@/api/repositories/Harbor.repository";

const repositories: any = {
    auth: { Repository: AuthRepository, instance: null },
    slot: { Repository: SlotRepository, instance: null },
    shipArrival: { Repository: ShipArrivalRepository, instance: null },
    booking: { Repository: BookingRepository, instance: null },
    vessel: { Repository: VesselRepository, instance: null },
    voyage: { Repository: VoyageRepository, instance: null },
    user: { Repository: UserRepository, instance: null },
    shipOwner: { Repository: ShipOwnerRepository, instance: null },
    upload: { Repository: UploadRepository, instance: null },
    harbor: { Repository: HarborRepository, instance: null},
    settings: { Repository: SettingsRepository, instance: null },
};
/**
 *  Factory to create repositories. All created repositories are singleton instances.
 */
export const RepositoryFactory = {
    get: (name: string) => {
        if (repositories[name].instance) {
            return repositories[name].instance;
        } else {
            return new repositories[name].Repository();
        }
    }
};
