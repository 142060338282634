import { RootState, ShipArrivalState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import ShipArrival from '@/models/ShipArrival.model';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import ShipArrivalRepository from '@/api/repositories/ShipArrival.repository';
import { BookingState } from '@/enums/BookingState.enum';
import Voyage from '@/models/Voyage.model';

const shipArrivalRepository: ShipArrivalRepository = RepositoryFactory.get('shipArrival');

function initialShipArrivalState(): ShipArrivalState {
    return {
        currentArrival: undefined,
        openArrivals: [],
        allArrivals: [],
        calendarArrival: undefined,
        calendarArrivalId: undefined,
    };
}

const store: ShipArrivalState = initialShipArrivalState();

/**
 * ACTION SECTION
 */
export enum shipArrivalStoreActions {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    GET = 'GET',
    GET_ALL = 'GET_ALL',
    GET_OPEN_SHIP_ARRIVALS = 'GET_OPEN_SHIP_ARRIVALS',
    UPDATE_CALENDAR_SHIP_ARRIVAL = 'UPDATE_CALENDAR_SHIP_ARRIVAL',
    SET_RELEVANT_CHANGES_FLAG = "SET_RELEVANT_CHANGES_FLAG"
}

const actions: ActionTree<ShipArrivalState, RootState> = {
    [shipArrivalStoreActions.GET_OPEN_SHIP_ARRIVALS]: async ({ commit },
                                                             payload?: { filter: string }): Promise<ShipArrival[]> => {
        const result = await shipArrivalRepository.getOpenShipArrivals(payload);
        const shipArrivals = ShipArrival.parseFromArray(result.data) as ShipArrival[];
        commit(shipArrivalStoreMutations.SET_OPEN_ARRIVALS, shipArrivals);
        return shipArrivals;
    },
    [shipArrivalStoreActions.GET_ALL]: async (
        { commit }, payload?: {
            filterText: string, status: BookingState, range: string[], withArchive: boolean }): Promise<ShipArrival[]> => {
        const result = await shipArrivalRepository.getAll(payload);
        const shipArrivals = ShipArrival.parseFromArray(result.data) as ShipArrival[];
        commit(shipArrivalStoreMutations.SET_ALL_ARRIVALS, shipArrivals);
        return shipArrivals;
    },
    // eslint-disable-next-line no-empty-pattern
    [shipArrivalStoreActions.GET]: async ({}, payload: { id: string }): Promise<ShipArrival> => {
        const result = await shipArrivalRepository.getById(payload);
        const shipArrival = ShipArrival.parseFromObject(result.data) as ShipArrival;
        return shipArrival;
    },
    // eslint-disable-next-line no-empty-pattern
    [shipArrivalStoreActions.CREATE]: async ({}, payload: { shipArrival: ShipArrival }): Promise<ShipArrival> => {
        const result = await shipArrivalRepository.create(payload);
        const shipArrival = ShipArrival.parseFromObject(result.data) as ShipArrival;
        return shipArrival;
    },
    // eslint-disable-next-line no-empty-pattern
    [shipArrivalStoreActions.UPDATE]: async ({}, payload: { shipArrival: Partial<ShipArrival> }): Promise<ShipArrival> => {
        const result = await shipArrivalRepository.update(payload);
        const shipArrival = ShipArrival.parseFromObject(result.data) as ShipArrival;
        return shipArrival;
    },
    // eslint-disable-next-line no-empty-pattern
    [shipArrivalStoreActions.DELETE]: async ({ commit }, payload: { id: string }): Promise<void> => {
        await shipArrivalRepository.delete(payload);
        commit(shipArrivalStoreMutations.REMOVE_SHIP_ARRIVAL, payload);
    },
    [shipArrivalStoreActions.UPDATE_CALENDAR_SHIP_ARRIVAL]: async ({ commit, getters }): Promise<ShipArrival> => {
        const oldShipArrival = getters[shipArrivalStoreGetter.CALENDAR_SHIP_ARRIVAL];
        const result = await shipArrivalRepository.getById({ id: oldShipArrival.id });
        const shipArrival = ShipArrival.parseFromObject(result.data) as ShipArrival;
        commit(shipArrivalStoreMutations.UPDATE_CALENDAR_SHIP_ARRIVAL, shipArrival);
        return shipArrival;
    },
    // eslint-disable-next-line no-empty-pattern
    [shipArrivalStoreActions.SET_RELEVANT_CHANGES_FLAG]:
        async ({ commit }, payload: { id: string, hasRelevantChanges: boolean }): Promise<ShipArrival> => {
        const result = await shipArrivalRepository.setRelevantChangesFlag(payload);
        const shipArrival = ShipArrival.parseFromObject(result.data) as ShipArrival;
        return shipArrival;
    },
};

/**
 * MUTATION SECTION
 */
export enum shipArrivalStoreMutations {
    SET_CURRENT_ARRIVAL = 'SET_CURRENT_ARRIVAL',
    SET_ALL_ARRIVALS = 'SET_ALL_ARRIVALS',
    SET_OPEN_ARRIVALS = 'SET_OPEN_ARRIVALS',
    SET_CALENDAR_ARRIVAL = 'SET_CALENDAR_ARRIVAL',
    UPDATE_CALENDAR_SHIP_ARRIVAL = 'UPDATE_CALENDAR_SHIP_ARRIVAL',
    SET_VOYAGE_FOR_CURRENT_SHIP_ARRIVAL = 'SET_VOYAGE_FOR_CURRENT_SHIP_ARRIVAL',
    REMOVE_SHIP_ARRIVAL = 'REMOVE_SHIP_ARRIVAL',
    CLEAR_STORE = 'CLEAR_STORE',
}

const mutations: MutationTree<ShipArrivalState> = {
    [shipArrivalStoreMutations.SET_OPEN_ARRIVALS]: (state: ShipArrivalState, value: ShipArrival[]) => {
        state.openArrivals = value;
    },
    [shipArrivalStoreMutations.SET_ALL_ARRIVALS]: (state: ShipArrivalState, value: ShipArrival[]) => {
        state.allArrivals = value;
    },
    [shipArrivalStoreMutations.SET_CALENDAR_ARRIVAL]: (state: ShipArrivalState, value: ShipArrival) => {
        if (value) {
            state.calendarArrival = value;
            state.calendarArrivalId = value.id;
        } else {
            state.calendarArrival = undefined;
            state.calendarArrivalId = undefined;
        }
    },
    [shipArrivalStoreMutations.UPDATE_CALENDAR_SHIP_ARRIVAL]: (state: ShipArrivalState, value: ShipArrival) => {
        // @ts-ignore
        Object.keys(value).forEach(key => {
        // @ts-ignore
            state.calendarArrival![key] = value[key];
        });
    },
    [shipArrivalStoreMutations.SET_CURRENT_ARRIVAL]: (state: ShipArrivalState, value: ShipArrival) => {
        state.currentArrival = value;
    },
    [shipArrivalStoreMutations.SET_VOYAGE_FOR_CURRENT_SHIP_ARRIVAL]: (state: ShipArrivalState, value: Voyage) => {
        state.currentArrival!.voyage = value;
    },
    [shipArrivalStoreMutations.REMOVE_SHIP_ARRIVAL]: (state: ShipArrivalState, value: { id: string }) => {
        // Remove from all
        const allIndex = state.allArrivals.findIndex(item => item.id === value.id);
        state.allArrivals.splice(allIndex, 1);
        // Remove from open
        const openIndex = state.openArrivals.findIndex(item => item.id === value.id);
        state.openArrivals.splice(openIndex, 1);
        // Reset calendar and current arrival
        state.calendarArrival = undefined;
        state.currentArrival = undefined;
    },
    [shipArrivalStoreMutations.CLEAR_STORE]: (state: ShipArrivalState) => {
        const initialState = initialShipArrivalState();
        Object.keys(initialState).forEach(key => {
            // @ts-ignore
            state[key] = initialState[key];
        });
    },
};

/**
 * GETTER SECTION
 */
export enum shipArrivalStoreGetter {
    CURRENT_ARRIVAL = 'CURRENT_ARRIVAL',
    LIST_ALL = 'LIST_ALL',
    LIST_OPEN = 'LIST_OPEN',
    CALENDAR_SHIP_ARRIVAL = 'CALENDAR_SHIP_ARRIVAL',
    CALENDAR_SHIP_ARRIVAL_ID = 'CALENDAR_SHIP_ARRIVAL_ID',
}

const getters: GetterTree<ShipArrivalState, RootState> = {
    [shipArrivalStoreGetter.LIST_OPEN]: (state: ShipArrivalState) => {
        return ShipArrival.parseFromArray(state.openArrivals);
    },
    [shipArrivalStoreGetter.LIST_ALL]: (state: ShipArrivalState) => {
        return ShipArrival.parseFromArray(state.allArrivals);
    },
    [shipArrivalStoreGetter.CALENDAR_SHIP_ARRIVAL]: (state: ShipArrivalState) => {
        return state.calendarArrival ? ShipArrival.parseFromObject(state.calendarArrival) : undefined;
    },
    [shipArrivalStoreGetter.CALENDAR_SHIP_ARRIVAL_ID]: (state: ShipArrivalState) => {
        return state.calendarArrivalId;
    },
    [shipArrivalStoreGetter.CURRENT_ARRIVAL]: (state: ShipArrivalState) => {
        return state.currentArrival ? ShipArrival.parseFromObject(state.currentArrival) : null;
    }
};

const shipArrivalStore: Module<ShipArrivalState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default shipArrivalStore;
