import { DateTime } from 'luxon';
import i18n from '@/i18n';

/**
 * Handler for all time events
 */
const TimeHandlerPlugin = {
    install(Vue: any) {

        /**
         * register isoString to humanized function for converting do date
         * @param isoString
         */
        const isoStringDateHumanized = (isoString: string) => {
            // create DateTime constant
            let dt;
            if (typeof isoString === 'string') { // Sometimes there seem to be date objects?
                dt = DateTime.fromISO(isoString);
            } else {
                dt = DateTime.fromISO((isoString as Date).toISOString())
            }
            // return humanized string with format
            return dt.toFormat(i18n.t('TIME_HANDLER.DATE_HUMANIZED').toString());
        };

        /**
         * register isoString to humanized function for converting do date time
         * @param isoString
         */
        const isoStringDateTimeHumanized = (isoString: string) => {
            // create DateTime constant
            let dt;
            if (typeof isoString === 'string') { // Sometimes there seem to be date objects?
                dt = DateTime.fromISO(isoString);
            } else {
                dt = DateTime.fromISO((isoString as Date).toISOString())
            }
            // return humanized string with format
            return dt.toFormat(i18n.t('TIME_HANDLER.DATE_TIME_HUMANIZED').toString());
        };

        const $isoStringWeekdayHumanized = (isoString: string) => {
            // create DateTime constant
            let dt;
            if (typeof isoString === 'string') { // Sometimes there seem to be date objects?
                dt = DateTime.fromISO(isoString);
            } else {
                dt = DateTime.fromISO((isoString as Date).toISOString())
            }
            // return humanized string with format
            return dt.toFormat(i18n.t('TIME_HANDLER.WEEKDAY_HUMANIZED').toString(), { locale: i18n.locale });
        };

        Vue.prototype.$isoStringDateHumanized= isoStringDateHumanized;
        Vue.isoStringDateHumanized = isoStringDateHumanized;
        Vue.prototype.$isoStringDateTimeHumanized = isoStringDateTimeHumanized;
        Vue.isoStringDateTimeHumanized = isoStringDateTimeHumanized;
        Vue.prototype.$isoStringWeekdayHumanized = $isoStringWeekdayHumanized;
        Vue.isoStringWeekdayHumanized = $isoStringWeekdayHumanized;
    }
};

export default TimeHandlerPlugin;
