import Parseable from '@/misc/Parseable';
import {BookingState} from '@/enums/BookingState.enum';
import {UserType} from '@/enums/UserType.enum';
import ShipArrival from '@/models/ShipArrival.model';
import Slot from "@/models/Slot.model";

export class Booking extends Parseable {

    public id?: string;
    public status?: BookingState;
    public shipArrival!: ShipArrival | string;
    public statusHistory: {
        status: BookingState,
        date: string,
        user: {
            id: string,
            type: UserType
        }
    }[] = [];
    public bookedSlots: Slot[] = [];

    public createdAt?: string;
    public updatedAt?: string;

    public static parseFromObject(object: Partial<Booking>): Booking {
        const booking = new Booking();

        if (typeof object.shipArrival === 'object') {
            object.shipArrival = ShipArrival.parseFromObject(object.shipArrival) as ShipArrival;
        }

        object.bookedSlots = Slot.parseFromArray(object.bookedSlots!) as Slot[];

        Object.assign(booking, object);
        return booking;
    }

    parseToObject(): Partial<Booking> {
        return {...this};
    }
}
