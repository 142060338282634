import Parseable from '@/misc/Parseable';
import { SlotType } from '@/enums/SlotType.enum';
import { ShiftLength } from '@/enums/ShiftLength.enum';
import { ShiftType } from '@/enums/ShiftType.enum';
import { Booking } from '@/models/Booking.model';

export default class Slot extends Parseable {

    id?: string;
    date!: string;
    slotType!: SlotType;
    shiftLength!: ShiftLength;
    shift!: ShiftType;
    amount!: number;
    booking!: Booking;
    isDeleted!: boolean;
    createdAt?: string;
    updatedAt?: string;

    public static parseFromObject(object: Partial<Slot>): Slot {
        const slot = new Slot();

        if (object.booking && typeof object.booking === 'object') {
            object.booking = Booking.parseFromObject(object.booking) as Booking;
        }

        Object.assign(slot, object);
        return slot;
    }

    parseToObject(): Partial<Slot> {
        return { ...this };
    }

}
