import store from '@/store';
import { authStoreGetter } from '@/store/auth.store';
import { UserRole } from '@/models/User.model';

/**
 * Permission Handler
 */
const PermissionHandlerPlugin = {
    install(Vue: any) {
        /**
         * check if user has extended permissions
         */
        const hasRole = (userRole: UserRole | UserRole[] | null): boolean => {
            if (!userRole) {
                return true;
            }

            if (!Array.isArray(userRole)) {
                userRole = [userRole];
            }
            return userRole.includes(store.getters[`auth/${authStoreGetter.CURRENT_USER}`].userRole);
        };

        Vue.prototype.$hasRole = hasRole;
        Vue.hasRole = hasRole;
    }
};

export default PermissionHandlerPlugin;
