import {RootState, SlotState} from '@/interfaces/storeStateInterfaces';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import SlotGroup from '@/models/SlotGroup.model';
import {ShiftType} from '@/enums/ShiftType.enum';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import SlotRepository from '@/api/repositories/Slot.repository';
import {DateTime} from 'luxon';
import SlotBookingRequest from '@/interfaces/SlotBookingRequest.interface';
import {SlotType} from '@/enums/SlotType.enum';
import {ShiftConfig} from '@/interfaces/ShiftConfig.interface';
import {Booking} from '@/models/Booking.model';
import Slot from '@/models/Slot.model';
import ShipArrival from '@/models/ShipArrival.model';
import {BookingState} from '@/enums/BookingState.enum';
import {SlotConfigInterface} from '@/interfaces/SlotConfig.interface';

const slotRepository: SlotRepository = RepositoryFactory.get('slot');

function initialSlotState(): SlotState {
    return {
        slotGroups: [],
        shiftConfig: null,
        slotConfig: null,
        slottingError: {}
    };
}

const store: SlotState = initialSlotState();

/**
 * ACTION SECTION
 */
export enum slotStoreActions {
    GET_SLOT_GROUPS = 'GET_SLOT_GROUPS',
    CREATE_SLOT = 'CREATE_SLOT',
    DELETE_SLOT = 'DELETE_SLOT',
    MOVE_SLOT = 'MOVE_SLOT',
    INCREASE_EXTRA_SLOTS = 'INCREASE_EXTRA_SLOTS',
    DECREASE_EXTRA_SLOTS = 'DECREASE_EXTRA_SLOTS',
    GET_SHIFT_CONFIG = 'GET_SHIFT_CONFIG',
    GET_SLOT_CONFIG = 'GET_SLOT_CONFIG',
}

const actions: ActionTree<SlotState, RootState> = {
    [slotStoreActions.GET_SLOT_GROUPS]: async ({ commit }, payload: { shift: ShiftType, date: string }[]): Promise<SlotGroup[]> => {
        const promises = payload.map(async item => {
            const response = await slotRepository.getSlotGroup(item);
            return response.data;
        });
        const result = await Promise.all(promises);
        const slotGroups = SlotGroup.parseFromArray(result) as SlotGroup[];
        commit(slotStoreMutations.SET_SLOT_GROUPS, slotGroups)
        return slotGroups;
    },
    [slotStoreActions.CREATE_SLOT]: async ({ commit }, payload: SlotBookingRequest): Promise<SlotGroup> => {
        const response = await slotRepository.createSlot(payload);
        const slotGroup = SlotGroup.parseFromObject(response.data) as SlotGroup;
        commit(slotStoreMutations.SET_SLOT_GROUP, slotGroup);
        return slotGroup;
    },
    [slotStoreActions.DELETE_SLOT]: async ({ commit }, payload: { slot: Slot }): Promise<Slot> => {
        const response = await slotRepository.deleteSlot(payload.slot);
        const slot = Slot.parseFromObject(response.data) as Slot;
        slot.isDeleted = true; // debug
        commit(slotStoreMutations.DELETE_SINGLE_SLOT, slot);
        return slot;
    },
    // eslint-disable-next-line no-empty-pattern
    [slotStoreActions.MOVE_SLOT]: async ({ commit }, payload: { slot: Slot, date: string, shift: ShiftType }): Promise<Slot> => {
        const response = await slotRepository.moveSlot(payload);
        return Slot.parseFromObject(response.data) as Slot;
    },
    [slotStoreActions.INCREASE_EXTRA_SLOTS]: async ({ commit }, payload:
        { shift: ShiftType, date: string, slotType: SlotType }): Promise<SlotGroup> => {
        const response = await slotRepository.increaseExtraSlot(payload);
        const slotGroup = SlotGroup.parseFromObject(response.data) as SlotGroup;
        commit(slotStoreMutations.SET_SLOT_GROUP, slotGroup);
        return slotGroup;
    },
    [slotStoreActions.DECREASE_EXTRA_SLOTS]: async ({ commit }, payload:
        { shift: ShiftType, date: string, slotType: SlotType }): Promise<SlotGroup> => {
        const response = await slotRepository.decreaseExtraSlot(payload);
        const slotGroup = SlotGroup.parseFromObject(response.data) as SlotGroup;
        commit(slotStoreMutations.SET_SLOT_GROUP, slotGroup);
        return slotGroup;
    },
    [slotStoreActions.GET_SHIFT_CONFIG]: async ({ commit }): Promise<ShiftConfig> => {
        const response = await slotRepository.getShiftConfig();
        const shiftConfig = response.data.singleSlotCapacity;
        commit(slotStoreMutations.SET_SHIFT_CONFIG, shiftConfig);
        return shiftConfig;
    },
    [slotStoreActions.GET_SLOT_CONFIG]: async ({ commit }, payload:
        { calendarWeek: number, year: number }): Promise<SlotConfigInterface> => {
        const response = await slotRepository.getSlotConfig(payload);
        const slotConfig = response.data;
        commit(slotStoreMutations.SET_SLOT_CONFIG, slotConfig);
        return slotConfig;
    }
};

/**
 * MUTATION SECTION
 */
export enum slotStoreMutations {
    CLEAR_STORE = 'CLEAR_STORE',
    SET_SLOT_GROUPS = 'SET_SLOT_GROUPS',
    SET_SLOT_GROUP = 'SET_SLOT_GROUP',
    SET_SHIFT_CONFIG = 'SET_SHIFT_CONFIG',
    CLEAR_SLOTTING_ERROR = 'CLEAR_SLOTTING_ERROR',
    UPDATE_SLOTS_BOOKING = 'UPDATE_SLOTS_BOOKING',
    UPDATE_RELEVANT_CHANGES = 'UPDATE_RELEVANT_CHANGES',
    DELETE_SINGLE_SLOT = 'DELETE_SINGLE_SLOT',
    SET_SLOT_CONFIG = 'SET_SLOT_CONFIG',
    SET_SLOTTING_ERROR = 'SET_SLOTTING_ERROR'
}

const mutations: MutationTree<SlotState> = {
    [slotStoreMutations.CLEAR_STORE]: (state: SlotState) => {
        const initialState = initialSlotState();
        Object.keys(initialState).forEach(key => {
            // @ts-ignore
            state[key] = initialState[key];
        });
    },
    [slotStoreMutations.SET_SLOT_GROUPS]: (state: SlotState, value: SlotGroup[]) => {
        sortSlotGroups(value)
        state.slotGroups = value;
    },
    [slotStoreMutations.SET_SLOT_CONFIG]: (state: SlotState, value: SlotConfigInterface) => {
        state.slotConfig = value;
    },
    /**
     * Updates a SlotGroup
     * @param state
     * @param value
     */
    [slotStoreMutations.SET_SLOT_GROUP]: (state: SlotState, slotGroup: SlotGroup) => {
        const index = state.slotGroups
            .findIndex(item =>
                item.date === slotGroup.date &&
                item.shift === slotGroup.shift
            );
        state.slotGroups[index] = slotGroup;
        state.slotGroups = [...state.slotGroups];
    },
    [slotStoreMutations.SET_SHIFT_CONFIG]: (state: SlotState, value: ShiftConfig) => {
        state.shiftConfig = value;
    },
    [slotStoreMutations.SET_SLOTTING_ERROR]: (state: SlotState, payload: { key: string, value: boolean }) => {
        state.slottingError[payload.key] = payload.value;
        state.slottingError = { ...state.slottingError };
    },
    [slotStoreMutations.UPDATE_SLOTS_BOOKING]: (state: SlotState, payload: Partial<Booking>) => {
        const slotGroupsCopy = SlotGroup.parseFromArray(state.slotGroups) as SlotGroup[];
        slotGroupsCopy.forEach((slotGroup: SlotGroup) => {
            [...slotGroup.bookedSlots.cars, ...slotGroup.bookedSlots.heavy]
                .forEach((slot: Slot, index: number) => {
                    if (slot.booking && (slot.booking.shipArrival as ShipArrival).id === (payload.shipArrival as string)) {
                        switch (payload.status) {
                            case BookingState.DECLINED:
                                // Remove DECLINED SLOTS
                                if (index <= slotGroup.bookedSlots.cars.length - 1) {
                                    // @ts-ignore
                                    slotGroup.bookedSlots.cars[index] = null;
                                    slotGroup.availableSlots.CAR += slot.amount;
                                } else {
                                    const processedIndex = index - slotGroup.bookedSlots.cars.length;
                                    // @ts-ignore
                                    slotGroup.bookedSlots.heavy[processedIndex] = null;
                                    slotGroup.availableSlots.HEAVY += slot.amount;
                                }
                                break;
                            default:
                                // Currently just for ACCEPTED
                                // Apply new data
                                slot.booking.status = payload.status;
                                slot.booking.statusHistory = payload.statusHistory!;
                        }
                    }
                });
            // Remove declined slots
            if (payload.status === BookingState.DECLINED) {
                slotGroup.bookedSlots.cars = slotGroup.bookedSlots.cars.filter(item => item);
                slotGroup.bookedSlots.heavy = slotGroup.bookedSlots.heavy.filter(item => item);
            }
        });
        state.slotGroups = slotGroupsCopy;
    },
    [slotStoreMutations.UPDATE_RELEVANT_CHANGES]: (state: SlotState, payload: Partial<ShipArrival>) => {
        const slotGroupsCopy = SlotGroup.parseFromArray(state.slotGroups) as SlotGroup[];
        slotGroupsCopy.forEach((slotGroup: SlotGroup) => {
            [...slotGroup.bookedSlots.cars, ...slotGroup.bookedSlots.heavy]
                .forEach((slot: Slot) => {
                    if (slot.booking && (slot.booking.shipArrival as ShipArrival).id === (payload.id as string)) {
                        (slot.booking.shipArrival as ShipArrival).hasRelevantChanges = false;
                    }
                });
        });
        state.slotGroups = slotGroupsCopy;
    },
    [slotStoreMutations.DELETE_SINGLE_SLOT]: (state: SlotState, payload: Partial<Slot>) => {
        const targetSlotGroup = state.slotGroups.find(slotGroup => {
            // TODO the payload (slot which should be deleted) has a time shift. That is why we cannot compare it directly
            //  like usual
            const compareDateSlotGroup = DateTime.fromISO(slotGroup.date);
            const compareDateSlot = DateTime.fromISO(payload.date!);
            // Check for date and shift
            return compareDateSlotGroup.hasSame(compareDateSlot, 'day') && payload.shift === slotGroup.shift;
        })!;
        if (payload.slotType === SlotType.CAR && targetSlotGroup) {
            targetSlotGroup.bookedSlots.cars = targetSlotGroup.bookedSlots.cars.filter(slot => slot.id !== payload.id);
            targetSlotGroup.availableSlots.CAR += payload.amount!;
        } else if (payload.slotType === SlotType.HEAVY && targetSlotGroup) {
            targetSlotGroup.bookedSlots.heavy = targetSlotGroup.bookedSlots.heavy.filter(slot => slot.id !== payload.id);
            targetSlotGroup.availableSlots.HEAVY += payload.amount!;
        }
    },
    [slotStoreMutations.CLEAR_SLOTTING_ERROR]: (state: SlotState) => {
        state.slottingError = {};
    }
};

/**
 * GETTER SECTION
 */
export enum slotStoreGetter {
    SLOT_GROUPS = 'SLOT_GROUPS',
    SHIFT_CONFIG = 'SHIFT_CONFIG',
    SLOT_CONFIG = 'SLOT_CONFIG',
    SLOTTING_ERROR = 'SLOTTING_ERROR',
}

const getters: GetterTree<SlotState, RootState> = {
    [slotStoreGetter.SLOT_GROUPS]: (state: SlotState) => state.slotGroups,
    [slotStoreGetter.SHIFT_CONFIG]: (state: SlotState) => state.shiftConfig,
    [slotStoreGetter.SLOT_CONFIG]: (state: SlotState) => state.slotConfig,
    [slotStoreGetter.SLOTTING_ERROR]: (state: SlotState) =>
        Object.keys(state.slottingError).some(key => state.slottingError[key]),
};

const slotStore: Module<SlotState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default slotStore;

// default function
/**
 * Sorts the slot groups asc and the shift within the days asc
 * @param slotGroups
 */
function sortSlotGroups(slotGroups: SlotGroup[]): void {
    slotGroups.sort((first: SlotGroup, second: SlotGroup) => {
        const firstDateTime = DateTime.fromISO(first.date!);
        const secondDateTime = DateTime.fromISO(second.date!);

        if (firstDateTime.toMillis() > secondDateTime.toMillis()) {
            return 1;
        } else if (firstDateTime.toMillis() < secondDateTime.toMillis()) {
            return -1;
        } else {
            // means both dates are from the same day both differ from shift
            if (first.shift === ShiftType.SECOND && second.shift === ShiftType.FIRST) {
                return 1;
            } else if (first.shift === ShiftType.THIRD && second.shift === ShiftType.SECOND) {
                return 1;
            } else if (first.shift === ShiftType.THIRD && second.shift === ShiftType.FIRST) {
                return 1;
            } else {
                return -1;
            }
        }
    })
}
