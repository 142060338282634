import {CalendarState, RootState} from '@/interfaces/storeStateInterfaces';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import CalendarItemIsSelectedDefinition from '@/interfaces/CalendarItemBorderDefinition.interface';
import {calendarCoordsConverting, createCalendarItemIsSelectedDefinition, setToSet} from '@/misc/helperMethods';
import SlotBookingRequest from '@/interfaces/SlotBookingRequest.interface';

function initialCalendarState(): CalendarState {
    return {
        selection: new Set<string>(),
        selectionInformation: undefined,
        slotSelectionInformation: undefined,
        selectionReference: undefined,
        itemFocussed: undefined,
        globalIsMouseDown: false,
        showContextMenu: false,
        showCreateContextMenu: false,
        generalContextMenuPositionX: 0,
        generalContextMenuPositionY: 0,
        boundaries: [],
        occupied: {},
        selectedWeek: undefined
    };
}

const store: CalendarState = initialCalendarState();

/**
 * ACTION SECTION
 */
export enum calendarStoreActions {
}

const actions: ActionTree<CalendarState, RootState> = {};

/**
 * MUTATION SECTION
 */
export enum calendarStoreMutations {
    CLEAR_STORE = 'CLEAR_STORE',
    CLEAR_SELECTION = 'CLEAR_SELECTION',
    ADD_SELECTION_ITEM = 'ADD_SELECTION_ITEM',
    SET_SELECTION_REFERENCE = 'SET_SELECTION_REFERENCE',
    SET_ITEM_FOCUSSED = 'SET_ITEM_FOCUSSED',
    GLOBAL_SET_IS_MOUSE_DOWN = 'GLOBAL_SET_IS_MOUSE_DOWN',
    UPDATE_SELECTION = 'UPDATE_SELECTION',
    SET_SHOW_CONTEXT_MENU = 'SET_SHOW_CONTEXT_MENU',
    SET_SHOW_CREATE_CONTEXT_MENU = 'SET_SHOW_CREATE_CONTEXT_MENU',
    SET_CONTEXT_MENU_POSITION = 'SET_CONTEXT_MENU_POSITION',
    SET_BOUNDARIES = 'SET_BOUNDARIES',
    ADD_OCCUPIED = 'ADD_OCCUPIED',
    CLEAR_OCCUPIED = 'CLEAR_OCCUPIED',
    SET_SELECTION_INFORMATION = 'SET_SELECTION_INFORMATION',
    SET_SELECTED_WEEK = 'SET_SELECTED_WEEK',
    SET_SLOT_SELECTION_INFORMATION = 'SET_SLOT_SELECTION_INFORMATION'
}

const mutations: MutationTree<CalendarState> = {
    [calendarStoreMutations.CLEAR_STORE]: (state: CalendarState) => {
        const initialState = initialCalendarState();
        Object.keys(initialState).forEach(key => {
            if (key !== 'selectedWeek') { // Don't clear the selected week
                // @ts-ignore
                state[key] = initialState[key];
            }
        });
    },
    [calendarStoreMutations.CLEAR_SELECTION]: (state: CalendarState) => {
        state.selection = new Set<string>();
    },
    [calendarStoreMutations.ADD_SELECTION_ITEM]: (state: CalendarState, payload: string) => {
        const tmpSet = setToSet(state.selection);
        const tmpArray = [...tmpSet];
        const splittedPayload = payload.split('-').map(item => Number(item));

        if (tmpArray.length === 0) {
            tmpArray[1] = payload;
            // Check if < xMin is added, if so just add this particular cell (this is a slot which is booked)
            if (splittedPayload[0] < state.boundaries[splittedPayload[1]].xMin) {
                tmpArray[0] = payload;
            } else {
                // Prepare payload coordinate in a way, that always the beginning is selected => x value => xMin
                splittedPayload[0] = state.boundaries[splittedPayload[1]].xMin;
                tmpArray[0] = splittedPayload.join('-');
            }
        } else {
            // Dont do anything if y value is different from both coordinates
            // (otherwise wrong not plausible coordinates can occur)
            const respectXMin = splittedPayload[0] >= state.boundaries[splittedPayload[1]].xMin;
            const respectXMax = splittedPayload[0] < state.boundaries[splittedPayload[1]].xMax;
            const changedYValue = splittedPayload[1] !== Number(tmpArray[0].split('-')[1]);
            if (changedYValue || !(respectXMin && respectXMax)) {
                return;
            }
            tmpArray[1] = payload;
        }
        state.selection = new Set<string>(tmpArray);
    },
    [calendarStoreMutations.GLOBAL_SET_IS_MOUSE_DOWN]: (state: CalendarState, payload: boolean) => {
        state.globalIsMouseDown = payload;
    },
    [calendarStoreMutations.SET_SELECTION_REFERENCE]: (state: CalendarState, payload: string) => {
        state.selectionReference = calendarCoordsConverting(payload);
    },
    [calendarStoreMutations.SET_ITEM_FOCUSSED]: (state: CalendarState, payload?: string) => {
        if (payload && state.selection.size > 0) {
            // keep the y coord from selection to avoid "jumping out" of focus reference
            // It is necessary to use always the last ( it is possible that just one coordinate is set)
            const selectionYRef = [...state.selection][state.selection.size - 1];
            state.itemFocussed = selectionYRef.split('-').map(item => Number(item));
        } else {
            state.itemFocussed = undefined;
        }
    },
    [calendarStoreMutations.UPDATE_SELECTION]: (state: CalendarState, payload: string) => {
        // Check if a change was performed and check if no negative coordinates are passed.
        // if payload.split('-').length !== 2 a negative value is passed. This is possible
        // if the user moves the selection with the keyboard the the outer top or left edge and beyond
        if (state.selectionReference!.join('-') !== payload && payload.split('-').length === 2) {
            // Calc new difference
            const [xRef, yRef] = state.selectionReference!;
            const [xNew, yNew] = calendarCoordsConverting(payload);
            const [xDiff, yDiff] = [xNew - xRef, yNew - yRef];
            // Add new difference
            const tmpSet = setToSet(state.selection);
            const updatedSet = new Set<string>();

            for (const value of [...tmpSet]) {
                const [xItem, yItem] = calendarCoordsConverting(value);
                // Check if the updated selection is outside of the selectable area
                if ((xItem + xDiff) < state.boundaries[yNew].xMin || (xItem + xDiff) >= state.boundaries[yNew].xMax ||
                    (yItem + yDiff) < 0 || (yItem + yDiff) > state.boundaries.length) {
                    return;
                }
                updatedSet.add(`${xItem + xDiff}-${yItem + yDiff}`);
            }
            // Update focussed ref
            state.itemFocussed = [state.itemFocussed![0] + xDiff, state.itemFocussed![1] + yDiff];
            // Update Selection
            state.selection = updatedSet;
            state.selectionReference = calendarCoordsConverting(payload);
        }
    },
    [calendarStoreMutations.SET_SHOW_CONTEXT_MENU]: (state: CalendarState, payload: boolean) => {
        state.showContextMenu = payload;
    },
    [calendarStoreMutations.SET_SHOW_CREATE_CONTEXT_MENU]: (state: CalendarState, payload: boolean) => {
        state.showCreateContextMenu = payload;
    },
    [calendarStoreMutations.SET_CONTEXT_MENU_POSITION]: (state: CalendarState, payload: { x: number, y: number }) => {
        state.generalContextMenuPositionX = payload.x;
        state.generalContextMenuPositionY = payload.y;
    },
    [calendarStoreMutations.SET_BOUNDARIES]: (state: CalendarState, payload: { xMin: number, xMax: number }[]) => {
        state.boundaries = payload;
    },
    [calendarStoreMutations.ADD_OCCUPIED]: (state: CalendarState, payload: { coords: string, data: any }) => {
        state.occupied[payload.coords] = payload.data;
    },
    [calendarStoreMutations.CLEAR_OCCUPIED]: (state: CalendarState) => {
        state.occupied = {};
    },
    [calendarStoreMutations.SET_SELECTION_INFORMATION]: (state: CalendarState, value: undefined | Partial<SlotBookingRequest>) => {
        state.selectionInformation = value;
    },
    [calendarStoreMutations.SET_SELECTED_WEEK]: (state: CalendarState, value: undefined | string) => {
        state.selectedWeek = value;
    },
    [calendarStoreMutations.SET_SLOT_SELECTION_INFORMATION]: (state: CalendarState, value: undefined | Partial<SlotBookingRequest>) => {
        state.slotSelectionInformation = value;
    }
};

/**
 * GETTER SECTION
 */
export enum calendarStoreGetter {
    GLOBAL_SELECTION = 'GLOBAL_SELECTION',
    GLOBAL_ACTION_SELECTION = 'GLOBAL_ACTION_SELECTION',
    GLOBAL_HAS_SELECTION = 'GLOBAL_HAS_SELECTION',
    ITEM_IS_FOCUSSED = 'ITEM_IS_FOCUSSED',
    ITEM_FOCUSSED = 'ITEM_FOCUSSED',
    ITEM_IS_SELECTED = 'ITEM_IS_SELECTED',
    GLOBAL_IS_MOUSE_DOWN = 'GLOBAL_IS_MOUSE_DOWN',
    GENERAL_CONTEXT_MENU_POSITION = 'GENERAL_CONTEXT_MENU_POSITION',
    SHOW_CONTEXT_MENU = 'SHOW_CONTEXT_MENU',
    SHOW_CREATE_CONTEXT_MENU = 'SHOW_CREATE_CONTEXT_MENU',
    IS_CONTEXT_MENU_ACTIVE = 'IS_CONTEXT_MENU_ACTIVE',
    GET_BOUNDARY = 'GET_BOUNDARY',
    GET_OCCUPIED = 'GET_OCCUPIED',
    SELECTION_INFORMATION = 'SELECTION_INFORMATION',
    SELECTED_WEEK = 'SELECTED_WEEK',
    SLOT_SELECTION_INFORMATION = 'SLOT_SELECTION_INFORMATION'
}

const getters: GetterTree<CalendarState, RootState> = {
    [calendarStoreGetter.GLOBAL_SELECTION]: (state: CalendarState) => {
        const tmpSet = setToSet(state.selection);

        // Maintain always the correct order (rectangle selection is constructed from left top to right bottom)!
        if (tmpSet.size > 1) {
            const tmpArray = [...tmpSet];
            const startCoords = tmpArray[0].split('-').map((item: string) => Number(item));
            const endCoords = tmpArray[1].split('-').map((item: string) => Number(item));
            const [xStart, xEnd] = startCoords[0] > endCoords[0] ? [endCoords[0], startCoords[0]] : [startCoords[0], endCoords[0]];
            const [yStart, yEnd] = startCoords[1] > endCoords[1] ? [endCoords[1], startCoords[1]] : [startCoords[1], endCoords[1]];
            return new Set<string>([`${xStart}-${yStart}`, `${xEnd}-${yEnd}`]);
        } else {
            return tmpSet;
        }
    },
    [calendarStoreGetter.GLOBAL_HAS_SELECTION]: (state: CalendarState) => state.selection.size > 0,
    [calendarStoreGetter.ITEM_IS_SELECTED]: (state: CalendarState) =>
        (payload: string): CalendarItemIsSelectedDefinition => {
            const tmpSet = setToSet(state.selection);
            return createCalendarItemIsSelectedDefinition(tmpSet, payload);
        },
    [calendarStoreGetter.ITEM_IS_FOCUSSED]: (state: CalendarState) => (payload: string): boolean => {
        return state.itemFocussed?.join('-') === payload;
    },
    [calendarStoreGetter.ITEM_FOCUSSED]: (state: CalendarState) => state.itemFocussed,
    [calendarStoreGetter.GLOBAL_IS_MOUSE_DOWN]: (state: CalendarState) => state.globalIsMouseDown,
    [calendarStoreGetter.GENERAL_CONTEXT_MENU_POSITION]: (state: CalendarState) => {
        return {
            x: state.generalContextMenuPositionX,
            y: state.generalContextMenuPositionY
        };
    },
    [calendarStoreGetter.SHOW_CONTEXT_MENU]: (state: CalendarState) => state.showContextMenu,
    [calendarStoreGetter.SHOW_CREATE_CONTEXT_MENU]: (state: CalendarState) => state.showCreateContextMenu,
    [calendarStoreGetter.IS_CONTEXT_MENU_ACTIVE]: (state: CalendarState) =>
        state.showContextMenu || state.showCreateContextMenu,
    [calendarStoreGetter.GET_BOUNDARY]: (state: CalendarState) =>
        (index: number): { xMin: number, xMax: number } => state.boundaries[index] ?? {xMin: 0, xMax: 0},
    [calendarStoreGetter.GET_OCCUPIED]: (state: CalendarState) =>
        (coords: string): any | undefined => state.occupied[coords],
    [calendarStoreGetter.SELECTION_INFORMATION]: (state: CalendarState) => state.selectionInformation,
    [calendarStoreGetter.SLOT_SELECTION_INFORMATION]: (state: CalendarState) => state.slotSelectionInformation,
    [calendarStoreGetter.SELECTED_WEEK]: (state: CalendarState) => {
        return state.selectedWeek;
    },
};

const calendarStore: Module<CalendarState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default calendarStore;
