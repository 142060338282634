











import {Component, Vue} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import {applicationStoreGetter} from "@/store/application.store";

const ApplicationStore = namespace('application');

@Component({})
export default class App extends Vue {

  @ApplicationStore.Getter(applicationStoreGetter.LOCALE)
  public currentLocale!: string;

  public maxNotifications = 2;

  public created() {
    this.$i18n.locale = this.currentLocale;
  }

}
