import {RootState, SettingsState} from '@/interfaces/storeStateInterfaces';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import SettingsRepository from "@/api/repositories/Settings.repository";
import {SlotSettings} from "@/interfaces/SlotSettings.interface";

const settingsRepository: SettingsRepository = RepositoryFactory.get('settings');

function initialSlotState(): SettingsState {
    return {
        settings:
            {
                id: "",
                lockedAmount: 5,
                userDeletionTime: 365,
                arrivalDeletionTime: 365
            },
    };
}

const store: SettingsState = initialSlotState();

/**
 * ACTION SECTION
 */
export enum settingsStoreActions {
    GET_SETTINGS = 'GET_SETTINGS',
    UPDATE_SETTINGS = 'UPDATE_SETTINGS',
}

const actions: ActionTree<SettingsState, RootState> = {
    [settingsStoreActions.UPDATE_SETTINGS]: async ({commit}, payload: { settings: SlotSettings }): Promise<SlotSettings> => {
        const response = await settingsRepository.updateSettings(payload.settings);
        return response.data;
    },
    [settingsStoreActions.GET_SETTINGS]: async ({commit}): Promise<SlotSettings> => {
        const response = await settingsRepository.getSettings();
        const settings = response.data;
        commit(settingsStoreMutations.SET_SETTINGS, settings);
        return settings;
    },
};

/**
 * MUTATION SECTION
 */
export enum settingsStoreMutations {
    CLEAR_STORE = 'CLEAR_STORE',
    SET_SETTINGS = 'SET_SETTINGS'
}

const mutations: MutationTree<SettingsState> = {
    [settingsStoreMutations.CLEAR_STORE]: (state: SettingsState) => {
        const initialState = initialSlotState();
        Object.keys(initialState).forEach(key => {
            // @ts-ignore
            state[key] = initialState[key];
        });
    },
    [settingsStoreMutations.SET_SETTINGS]: (state: SettingsState, value: SlotSettings) => {
        state.settings = value;
    },
};

/**
 * GETTER SECTION
 */
export enum settingsStoreGetter {
    SETTINGS = 'SETTINGS',
}

const getters: GetterTree<SettingsState, RootState> = {
    [settingsStoreGetter.SETTINGS]: (state: SettingsState) => state.settings,
};

const settingsStore: Module<SettingsState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default settingsStore;
