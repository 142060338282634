import {AxiosResponse} from 'axios';
import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import ShipOwner from '@/models/ShipOwner.model';

export default class ShipOwnerRepository extends EntityBaseRepository {

    public getAll(payload?: { status: boolean }): Promise<AxiosResponse> {
        return this.axiosClient.get(`/shipOwner${payload ? '?status=true': ''}`);
    }

    public getById(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.get(`/shipOwner/${payload.id}`);
    }

    public update(payload: { id: string, status: boolean, locale: string, email?: string[] }): Promise<AxiosResponse> {
        const params: Partial<ShipOwner> = {status: payload.status, locale: payload.locale};
        if (payload.email) {
            params.email = payload.email;
        }
        return this.axiosClient.put(`/shipOwner/${payload.id}`, params);
    }

    public delete(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.delete(`/shipOwner/${payload.id}`);
    }
}
