import Parseable from '@/misc/Parseable';


export default class Harbor extends Parseable {

  id!: string;
  csvId!: string;
  countryCode!: string;
  country!: string;
  city!: string;
  description!: string;

  constructor() {
    super();
  }

  public static parseFromObject(object: Partial<Harbor>): Harbor {
    const harbor = new Harbor();
    Object.assign(harbor, object);

    return harbor;
  }

  public parseToObject(): Harbor {
    return {...this};
  }
}
