import { RootState, VoyageStoreState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import VoyageRepository from '@/api/repositories/Voyage.repository';
import Voyage from '@/models/Voyage.model';

const voyageRepository: VoyageRepository = RepositoryFactory.get('voyage');

function initialVoyageState(): VoyageStoreState {
    return {};
}

const store: VoyageStoreState = initialVoyageState();

/**
 * ACTION SECTION
 */
export enum voyageStoreActions {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE'
}

const actions: ActionTree<VoyageStoreState, RootState> = {
    // eslint-disable-next-line no-empty-pattern
    [voyageStoreActions.CREATE]: async ({ }, payload: Voyage): Promise<Voyage> => {
        const voyageResponse = await voyageRepository.create(payload);
        return voyageResponse.data;
    },
    // eslint-disable-next-line no-empty-pattern
    [voyageStoreActions.UPDATE]: async ({ }, payload: Partial<Voyage>): Promise<Voyage> => {
        const voyageResponse = await voyageRepository.update(payload);
        return voyageResponse.data;
    },
};

/**
 * MUTATION SECTION
 */
export enum voyageStoreMutations {
    CLEAR_STORE = 'CLEAR_STORE',
}

const mutations: MutationTree<VoyageStoreState> = {
    [voyageStoreMutations.CLEAR_STORE]: (state: VoyageStoreState) => {
        const initialState = initialVoyageState();
        Object.keys(initialState).forEach(key => {
            // @ts-ignore
            state[key] = initialState[key];
        });
    },
};

/**
 * GETTER SECTION
 */
export enum vesselStoreGetter {

}

const getters: GetterTree<VoyageStoreState, RootState> = {};

const voyageStore: Module<VoyageStoreState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default voyageStore;
