import Parseable from '@/misc/Parseable';

export default class ShipOwner extends Parseable {
    id?: string;
    partnerId?: number;
    partnerCode?: number;
    color?: string;
    name?: string;
    city?: string;
    street?: string;
    zipCode?: string;
    country?: string;
    countryCode?: string;
    status!: boolean;
    isDeleted!: boolean;
    locale?: string;
    email!: string[];

    public static parseFromObject(object: Partial<ShipOwner>): ShipOwner {
        const shipOwner = new ShipOwner();
        Object.assign(shipOwner, object);
        return shipOwner;
    }

    public parseToObject(): ShipOwner {
        return { ...this };
    }
}
