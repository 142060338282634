import Parseable from '@/misc/Parseable';
import ShipOwner from '@/models/ShipOwner.model';

export enum UserRole {
    IT_AUTOMOBILE = 'IT_AUTOMOBILE',
    SHIP_OWNER = 'SHIP_OWNER',
    KEY_USER = 'KEY_USER',
    PLANER = 'PLANER'
}

export default class User extends Parseable {

    public id?: string;
    public email?: string;
    public salutation?: string;
    public firstname?: string;
    public lastname?: string;
    public username?: string;
    public status?: boolean;
    public lastLogin!: Date;
    public userRole?: UserRole;
    public shipOwners?: string[] | ShipOwner[];

    public static parseFromObject(object: any): User {
        const user = new User();
        Object.assign(user, object);

        user.lastLogin = new Date(object.lastLogin);

        return user;
    }

    public parseToObject(): Partial<User> {
        const tmp = {...this};
        return tmp;
    }
}
