import {AxiosResponse} from 'axios';
import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import ShipArrival from '@/models/ShipArrival.model';
import Voyage from '@/models/Voyage.model';
import {BookingState} from '@/enums/BookingState.enum';
import ShipOwner from '@/models/ShipOwner.model';

export default class ShipArrivalRepository extends EntityBaseRepository {

    public getOpenShipArrivals(payload?: { filter: string }): Promise<AxiosResponse> {

        let queryParams;
        if (payload?.filter) {
            const searchParams = new URLSearchParams();
            searchParams.append('filter', payload.filter);
            queryParams = `?${searchParams.toString()}`;
        }

        return this.axiosClient.get(`/shipArrival/getOpen${queryParams ?? ''}`);
    }

    public getById(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.get(`/shipArrival/${payload.id}`);
    }

    public setRelevantChangesFlag(payload: { id: string, hasRelevantChanges: boolean }): Promise<AxiosResponse> {
        return this.axiosClient.post(`/shipArrival/${payload.id}/setRelevantChangesFlag`,
            { hasRelevantChanges: payload.hasRelevantChanges });
    }

    public getAll(payload?: {
        filterText: string, status: BookingState, range: string[], withArchive: boolean
    }): Promise<AxiosResponse> {
        let queryParams;
        if (payload) {
            const searchParams = new URLSearchParams();
            if (payload.filterText) {
                searchParams.append('filter', payload.filterText);
            }
            if (payload.status) {
                searchParams.append('status', payload.status);
            }
            if (payload.withArchive) {
                searchParams.append('withArchive', 'true');
            }
            if (payload.range) {
                searchParams.append('rangeStart', payload.range[0]);
                searchParams.append('rangeEnd', payload.range[1]);
            }
            queryParams = `?${searchParams.toString()}`;
        }

        return this.axiosClient.get(`/shipArrival${queryParams ?? ''}`);
    }

    public download(payload: {arrival: ShipArrival, fileType: string}): Promise<AxiosResponse> {
        return this.axiosClient.get(`/export/getExportFile/${payload.arrival.id}?type=${payload.fileType.toLowerCase()}`,
          {responseType: 'blob'});
    }

    public downloadMany(payload: {startDate: string, endDate: string, type: string, shipOwner: string}): Promise<any> {
        return this.axiosClient.get(`/export/getExportFiles?from=${payload.startDate}&to=${payload.endDate}` +
            `&type=${payload.type.toLowerCase()}&shipOwner=${payload.shipOwner}`,
            {responseType: 'blob'});
    }

    public downloadCollection(payload: {startDate: string, endDate: string, shipOwner: string, sorting: string, locale: string,
    filter: string}): Promise<any> {
        return this.axiosClient.get(`/export/getExportFilesCollection?from=${payload.startDate}&to=${payload.endDate}` +
            `&shipOwner=${payload.shipOwner}&sorting=${payload.sorting}&locale=${payload.locale}&filter=${payload.filter}`,
            {responseType: 'blob'});
    }

    public create(payload: { shipArrival: ShipArrival }): Promise<AxiosResponse> {
        const params = payload.shipArrival;
        params.voyage = (payload.shipArrival.voyage as Voyage).id!;
        params.shipOwner = (payload.shipArrival.shipOwner as ShipOwner).id!
        return this.axiosClient.post('/shipArrival', params);
    }

    public update(payload: { shipArrival: Partial<ShipArrival> }): Promise<AxiosResponse> {
        const params = payload.shipArrival;
        params.voyage = (payload.shipArrival.voyage as Voyage).id!;
        params.shipOwner = (payload.shipArrival.shipOwner as ShipOwner).id!
        return this.axiosClient.put(`/shipArrival/${params.id}`, params);
    }

    public delete(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.delete(`/shipArrival/${payload.id}`);
    }
}
