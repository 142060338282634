import AuthBaseRepository from '@/api/misc/AuthBaseRepository';
import {AxiosResponse} from 'axios';

export default class AuthRepository extends AuthBaseRepository {

    public login(payload: { username: string, password: string }): Promise<AxiosResponse> {
        return this.axiosClient.post('slotAuth/login', {username: payload.username, password: payload.password});
    }

    public setPassword(payload: { password: string, token: string }): Promise<AxiosResponse> {
        return this.axiosClient.post('auth/processPasswordToken', payload);
    }

    public requestPasswordMail(payload: { email: string }): Promise<AxiosResponse> {
        return this.axiosClient.post('auth/resetPassword', payload);
    }

    public resendInvitationMail(payload: { email: string }): Promise<AxiosResponse> {
        return this.axiosClient.post('auth/invitation', payload);
    }
}
