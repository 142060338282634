import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from '@/interfaces/storeStateInterfaces';
import authStore from '@/store/auth.store';
import applicationStore from '@/store/application.store';
import VuexPersistence from 'vuex-persist';
import calendarStore from '@/store/calendar.store';
import slotStore from '@/store/slot.store';
import shipArrivalStore from '@/store/shipArrival.store';
import vesselStore from '@/store/vessel.store';
import userStore from '@/store/user.store';
import shipOwnerStore from '@/store/shipOwner.store';
import bookingStore from '@/store/booking.store';
import voyageStore from '@/store/voyage.store';
import harborStore from "@/store/harbor.store";
import settingsStore from "@/store/settings.store";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<RootState>({
    storage: window.localStorage,
    key: process.env.VUE_APP_VUEX_KEY,
    modules: ['auth', 'application']
});

const store: StoreOptions<RootState> = {
    modules: {
        auth: {
            namespaced: true,
            ...authStore
        },
        application: {
            namespaced: true,
            ...applicationStore
        },
        calendar: {
            namespaced: true,
            ...calendarStore
        },
        slot: {
            namespaced: true,
            ...slotStore
        },
        shipArrival: {
            namespaced: true,
            ...shipArrivalStore
        },
        harbor: {
            namespaced: true,
            ...harborStore
        },
        vessel: {
            namespaced: true,
            ...vesselStore
        },
        voyage: {
            namespaced: true,
            ...voyageStore
        },
        user: {
            namespaced: true,
            ...userStore
        },
        shipOwner: {
            namespaced: true,
            ...shipOwnerStore
        },
        booking: {
            namespaced: true,
            ...bookingStore
        },
        settings: {
            namespaced: true,
            ...settingsStore
        },
    },
    plugins: [vuexLocal.plugin]
};

export default new Vuex.Store<RootState>(store);
