import { BookingStoreState, RootState } from '@/interfaces/storeStateInterfaces';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import { BookingState } from '@/enums/BookingState.enum';
import BookingRepository from '@/api/repositories/Booking.repository';
import { Booking } from '@/models/Booking.model';

const bookingRepository: BookingRepository = RepositoryFactory.get('booking');

function initialBookingStoreState(): BookingStoreState {
    return {};
}

const store: BookingStoreState = initialBookingStoreState();

/**
 * ACTION SECTION
 */
export enum bookingStoreActions {
    SET_STATUS = 'SET_STATUS',
}

const actions: ActionTree<BookingStoreState, RootState> = {
    [bookingStoreActions.SET_STATUS]: async ({ commit }, payload: { booking: string, status: BookingState }): Promise<Partial<Booking>> => {
        const result = await bookingRepository.setStatus({ id: payload.booking, status: payload.status});
        return result.data;
    }
};

/**
 * MUTATION SECTION
 */
export enum bookingStoreMutations {
    CLEAR_STORE = 'CLEAR_STORE'
}

const mutations: MutationTree<BookingStoreState> = {
    [bookingStoreMutations.CLEAR_STORE]: (state: BookingStoreState) => {
        const initialState = initialBookingStoreState();
        Object.keys(initialState).forEach(key => {
            // @ts-ignore
            state[key] = initialState[key];
        });
    },
};

/**
 * GETTER SECTION
 */
export enum bookingStoreGetter {}

const getters: GetterTree<BookingStoreState, RootState> = {
};

const bookingStore: Module<BookingStoreState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default bookingStore;
