export enum BookingState {
    ALL = 'ALL',
    RESERVED = 'RESERVED',
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED',
    CANCELED = 'CANCELED',
    DELETED = 'DELETED',
    NO_BOOKING = 'NO_BOOKING',
    // Frontend only state
    OCCUPIED = 'OCCUPIED'
}
