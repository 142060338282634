import Parseable from '@/misc/Parseable';

export default class Vessel extends Parseable {
    id?: string;
    code?: string;
    name?: string;
    length?: number;
    status?: boolean;
    isDeleted!: boolean;

    public static parseFromObject(object: Partial<Vessel>): Vessel {
        const vessel = new Vessel();
        Object.assign(vessel, object);
        return vessel;
    }

    public parseToObject(): Vessel {
        return { ...this };
    }
}
