import {RootState, ShipOwnerState} from '@/interfaces/storeStateInterfaces';
import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import ShipOwnerRepository from '@/api/repositories/ShipOwner.repository';
import ShipOwner from '@/models/ShipOwner.model';

const shipOwnerRepository: ShipOwnerRepository = RepositoryFactory.get('shipOwner');

function initialShipOwnerState(): ShipOwnerState {
    return {
        allShipOwners: []
    };
}

const store: ShipOwnerState = initialShipOwnerState();

/**
 * ACTION SECTION
 */
export enum shipOwnerStoreActions {
    GET = 'GET',
    GET_ALL = 'GET_ALL',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

const actions: ActionTree<ShipOwnerState, RootState> = {
    [shipOwnerStoreActions.GET_ALL]: async ({commit}, payload?: { status: boolean }): Promise<ShipOwner[]> => {
        const result = await shipOwnerRepository.getAll(payload);
        const shipOwners = ShipOwner.parseFromArray(result.data) as ShipOwner[];
        commit(shipOwnerStoreMutations.SET_ALL_SHIPOWNERS, shipOwners);
        return shipOwners;
    },
    // eslint-disable-next-line no-empty-pattern
    [shipOwnerStoreActions.GET]: async ({}, payload: { id: string }): Promise<ShipOwner> => {
        const result = await shipOwnerRepository.getById(payload);
        const shipOwner = ShipOwner.parseFromObject(result.data) as ShipOwner;
        return shipOwner;
    },
    [shipOwnerStoreActions.UPDATE]: async ({commit}, payload:
        { id: string, status: boolean, locale: string, email?: string[] }): Promise<ShipOwner> => {
        const result = await shipOwnerRepository.update(payload);
        const shipOwner = ShipOwner.parseFromObject(result.data) as ShipOwner;
        commit(shipOwnerStoreMutations.UPDATE_SHIP_OWNER, shipOwner);
        return shipOwner;
    },
    [shipOwnerStoreActions.DELETE]: async ({commit}, payload:
        { id: string }): Promise<ShipOwner> => {
        const result = await shipOwnerRepository.delete(payload);
        const shipOwner = ShipOwner.parseFromObject(result.data) as ShipOwner;
        commit(shipOwnerStoreMutations.REMOVE_SHIP_OWNER, shipOwner);
        return shipOwner;
    },
};

/**
 * MUTATION SECTION
 */
export enum shipOwnerStoreMutations {
    SET_ALL_SHIPOWNERS = 'SET_ALL_SHIPOWNERS',
    UPDATE_SHIP_OWNER = 'UPDATE_SHIP_OWNER',
    REMOVE_SHIP_OWNER = 'REMOVE_SHIP_OWNER',
    CLEAR_STORE = 'CLEAR_STORE',
}

const mutations: MutationTree<ShipOwnerState> = {
    [shipOwnerStoreMutations.SET_ALL_SHIPOWNERS]: (state: ShipOwnerState, value: ShipOwner[]) => {
        state.allShipOwners = value;
    },
    [shipOwnerStoreMutations.UPDATE_SHIP_OWNER]: (state: ShipOwnerState, value: ShipOwner) => {
        const index = state.allShipOwners
            .findIndex(item =>
                item.id === value.id
            );
        state.allShipOwners[index] = value;
        state.allShipOwners = [...state.allShipOwners];
    },
    [shipOwnerStoreMutations.REMOVE_SHIP_OWNER]: (state: ShipOwnerState, value: ShipOwner) => {
        state.allShipOwners = state.allShipOwners.filter(owner => owner.id !== value.id);
    },
    [shipOwnerStoreMutations.CLEAR_STORE]: (state: ShipOwnerState) => {
        const initialState = initialShipOwnerState();
        Object.keys(initialState).forEach(key => {
            // @ts-ignore
            state[key] = initialState[key];
        });
    },
};

/**
 * GETTER SECTION
 */
export enum shipOwnerStoreGetter {
    LIST_ALL = 'LIST_ALL',

}

const getters: GetterTree<ShipOwnerState, RootState> = {
    [shipOwnerStoreGetter.LIST_ALL]: (state: ShipOwnerState) => {
        return ShipOwner.parseFromArray(state.allShipOwners);
    },
};

const shipOwnerStore: Module<ShipOwnerState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default shipOwnerStore;
