/**
 * All navigation menu items
 */
import {NavigationMenuItemInterface} from '@/interfaces/NavigationMenuItem.interface';
import {ROUTE_CALENDAR, ROUTE_SETTINGS, ROUTE_SHIP_ARRIVAL} from '@/router/routes';
import {UserRole} from '@/models/User.model';

export const navigationMenuItems: NavigationMenuItemInterface[] = [{
    name: 'NAVIGATION_ITEMS.CALENDAR',
    route: ROUTE_CALENDAR,
    icon: 'fas fa-calendar-alt',
    isActive: false,
    neededUserRole: [UserRole.KEY_USER, UserRole.IT_AUTOMOBILE, UserRole.PLANER]
}, {
    name: 'NAVIGATION_ITEMS.VOYAGE',
    route: ROUTE_SHIP_ARRIVAL,
    icon: 'fas fa-ship',
    isActive: false,
    neededUserRole: null
}, {
    name: 'NAVIGATION_ITEMS.SETTINGS',
    route: ROUTE_SETTINGS,
    icon: 'fas fa-cog',
    isActive: false,
    neededUserRole: [UserRole.KEY_USER, UserRole.IT_AUTOMOBILE, UserRole.PLANER]
},
];
