import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from 'vuetify/src/locale/en';
import de from 'vuetify/src/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { en, de },
        current: 'de'
    },
    icons: {
        iconfont: 'fa'
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                /* Colors */
                primary: '#253652',
                secondary: '#f1f1f1',
                accent: '#253652',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#fbbb11',
                background: '#ffffff',
                surface: '#F8F9FA',
                divider: '#DBDBDB',
                'close-icon': '#C0C0C0',
                'icon-background': '#dfe1e5',
                'disabled': '#f1f1f1',
                'form-control-color': '#FFFFFF',
                'status-accepted': '#DFEECF',
                'status-reservation': '#FCF1AB',
                /* Fonts */
                'on-primary': '#ffffff',
                'on-secondary': '#000000',
                'on-secondary-2': '#000000',
                'on-surface': '#000000',
                'on-status-accepted': '#889F6F',
                'on-status-reservation': '#A69B59',
                /* Calendar */
                'calendar-background': '#f7f8f9',
                'calendar-item': '#ffffff',
                'calendar-item-disabled': '#dcdddd',
                'calendar-header': '#f7f8f9',
                'calendar-date': '#f7f8f9',
                'calendar-date-saturday': '#f2d796',
                'calendar-date-sunday': '#f2cdc1',
                'calendar-date-today': '#c1f2d0',
                'calendar-date-special-day': '#f2cdc1',
                'calendar-item-highlight': '#e3e5e5',
                'calendar-item-selected': '#489fdc',
                'calendar-item-focussed-bg': '#4CAF50',
                'calendar-item-selected-bg': '#87CEFA',
                'calendar-item-grouped': '#DCF17E',
                'calendar-item-ship-owner': '#F08267',
            }
        }
    }
});
