import {AxiosResponse} from 'axios';
import UploadBaseRepository from "@/api/misc/UploadBaseRepository";

export default class UploadRepository extends UploadBaseRepository {
    public uploadVesselCsv(payload: { file: File }): Promise<AxiosResponse> {
        const bodyFormData = new FormData();
        bodyFormData.append('file', payload.file);
        return this.axiosClient.post('/vessel/import', bodyFormData);
    }

    public uploadShipOwnerCsv(payload: { file: File }): Promise<AxiosResponse> {
        const bodyFormData = new FormData();
        bodyFormData.append('file', payload.file);
        return this.axiosClient.post('/shipOwner/import', bodyFormData);
    }

    public uploadShipArrivalCsv(payload: { file: File }): Promise<AxiosResponse> {
        const bodyFormData = new FormData();
        bodyFormData.append('file', payload.file);
        return this.axiosClient.post('/shipArrival/import', bodyFormData);
    }
}
