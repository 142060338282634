import {AxiosResponse} from 'axios';
import EntityBaseRepository from '@/api/misc/EntityBaseRepository';

export default class VesselRepository extends EntityBaseRepository {

    public getAll(payload?: { filterText: string }): Promise<AxiosResponse> {
        let queryParams;
        if (payload?.filterText) {
            const searchParams = new URLSearchParams();
            searchParams.append('filter', payload.filterText);
            queryParams = `?${searchParams.toString()}`;
        }

        return this.axiosClient.get(`/vessel${queryParams ?? ''}`);
    }

    public getById(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.get(`/vessel/${payload.id}`);
    }

    public update(payload: { id: string, status: boolean }): Promise<AxiosResponse> {
        return this.axiosClient.put(`/vessel/${payload.id}`, { status: payload.status });
    }

    public delete(payload: { id: string }): Promise<AxiosResponse> {
        return this.axiosClient.delete(`/vessel/${payload.id}`);
    }
}
