import { AxiosResponse } from 'axios';
import EntityBaseRepository from '@/api/misc/EntityBaseRepository';
import Voyage from '@/models/Voyage.model';
import Vessel from '@/models/Vessel.model';

export default class VoyageRepository extends EntityBaseRepository {

    public create(payload: Voyage): Promise<AxiosResponse> {
        payload.vessel = (payload.vessel as Vessel).id!;
        return this.axiosClient.post('/voyage', payload);
    }

    public update(payload: Partial<Voyage>): Promise<AxiosResponse> {
        payload.vessel = (payload.vessel as Vessel).id!;
        return this.axiosClient.put(`/voyage/${payload.id}`, payload);
    }
}
