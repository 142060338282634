import Parseable from '@/misc/Parseable';
import Vessel from '@/models/Vessel.model';

export default class Voyage extends Parseable {
    id?: string;
    contactInformation!: string;
    voyageNoImport!: string;
    voyageNoExport!: string;
    eta!: string | Date;
    ets!: string | Date;
    plannedEta!: string | Date;
    plannedEts!: string | Date;
    transShipment!: string;
    remarks!: string;
    draughtIn!: number;
    draughtOut!: number;
    lastPorts: string[] = [];
    nextPorts: string[] = [];
    trade!: string;
    vessel!: Vessel | string;

    public static parseFromObject(object: Partial<Voyage>): Voyage {
        const voyage = new Voyage();

        if(typeof object.eta === 'string'){
            object.eta = new Date(object.eta);
        }

        if(typeof object.ets === 'string'){
            object.ets = new Date(object.ets);
        }

        if(typeof object.plannedEta === 'string'){
            object.plannedEta = new Date(object.plannedEta);
        }

        if(typeof object.plannedEts === 'string'){
            object.plannedEts = new Date(object.plannedEts);
        }

        if(typeof object.vessel === 'object'){
            object.vessel = Vessel.parseFromObject(object.vessel) as Vessel;
        }


        Object.assign(voyage, object);
        return voyage;
    }

    public parseToObject(): Voyage {
        return { ...this };
    }
}
